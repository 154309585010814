import React from 'react';
import PT from 'prop-types';

import { Text, FlexLayout } from '~/ui';
import { useModal } from '~/hooks';

function SanctionDetailsModal() {
  const { metaData } = useModal();

  const SanctionDetail = ({ name, value }) => {
    const newValue = value || '';
    return (
      newValue && (
        <FlexLayout justifyContent="space-between">
          <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
            {name}
          </Text>
          <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
            {newValue}
          </Text>
        </FlexLayout>
      )
    );
  };

  SanctionDetail.propTypes = {
    name: PT.string,
    value: PT.string,
  };

  return (
    <FlexLayout flexDirection="column" sx={{ overflowY: 'auto', maxHeight: '560px' }}>
      {metaData.map((sanction, index) => {
        const {
          name,
          DOB,
          countryOfBirth,
          nationality,
          passportDetails,
          concatenatedAddress,
          otherInformation,
          groupType,
          aliasType,
          regime,
          listedOn,
          lastUpdated,
          sanctionsList,
        } = sanction;

        return (
          <FlexLayout
            space={2}
            flexDirection="column"
            variant="primary"
            mt={6}
            sx={{
              backgroundColor: 'body',
              width: '560px',
            }}
            key={index}
            justifyContent="space-between"
            flexFlow="row nowrap"
          >
            <FlexLayout alignItems="center" mb={5}>
              <Text
                variant="label-m"
                color="white"
                sx={{
                  backgroundColor: 'red',
                  borderRadius: 'round',
                  lineHeight: '36px',
                  width: '36px',
                  textAlign: 'center',
                }}
              >
                {index + 1}
              </Text>
              <Text variant="label-l" color="white" ml={2}>
                {name}
              </Text>
            </FlexLayout>
            <SanctionDetail name="Name" value={name} />
            <SanctionDetail name="DOB" value={DOB} />
            <SanctionDetail name="Country of Birth" value={countryOfBirth} />
            <SanctionDetail name="Nationality" value={nationality} />
            <SanctionDetail name="Passport Details" value={passportDetails} />
            <SanctionDetail name="Address" value={concatenatedAddress} />
            <SanctionDetail name="Group Type" value={groupType} />
            <SanctionDetail name="Alias Type" value={aliasType} />
            <SanctionDetail name="Regime" value={regime} />
            <SanctionDetail name="Sanctions List" value={sanctionsList} />
            <SanctionDetail name="Listed On" value={listedOn} />
            <SanctionDetail name="Last Updated" value={lastUpdated} />
            {otherInformation && (
              <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
                Aditional Information
              </Text>
            )}
            {otherInformation && (
              <Text variant="label-m" color="white">
                {otherInformation}
              </Text>
            )}
          </FlexLayout>
        );
      })}
    </FlexLayout>
  );
}

SanctionDetailsModal.propTypes = {
  data: PT.any,
};

export default SanctionDetailsModal;
