import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ContentWindow } from '~/components';

function App() {
  return (
    <BrowserRouter>
      <ContentWindow />
    </BrowserRouter>
  );
}

export default App;
