import React from 'react';

import { useAuth } from '~/hooks';
import { FlexLayout, Link, Text } from '~/ui';
import DropdownMenu from './DropdownMenu';

function Header() {
  const { removeLoggedInUser } = useAuth();
  return (
    <FlexLayout alignItems="center" justifyContent="space-between" px={6}>
      <FlexLayout space={10} alignItems="center">
        <Text variant="heading-semibold-m">Virturis</Text>
        <Link title="Dashboard" path="/dashboard" icon="briefcase" iconColor="white"></Link>
        <Link title="Results" path="/results" icon="bar"></Link>
      </FlexLayout>
      <DropdownMenu
        sx={{ top: 2 }}
        options={[
          {
            label: 'Log Out',
            onClick: () => {
              removeLoggedInUser();
            },
            icon: 'lock',
          },
        ]}
      />
    </FlexLayout>
  );
}

export default Header;
