import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import modalConstants from '~/constants/modalConstants';
import { NewAddressModal, NewCaseModal, ConfirmDeleteModal } from '~/components';
import { useModal } from '~/hooks';
import { FlexLayout, Card } from '~/ui';
import { SanctionDetailsModal } from '~/components';

function Modal() {
  const modalRoot = document.getElementById('modal-root');

  const { modalType, isOpen, hideModal, width, title, metaData } = useModal();

  useEffect(() => {
    function handleKeyPressed(event) {
      if (event.key === 'Escaped' || event.keyCode === 27) {
        hideModal();
      }
    }
    window.addEventListener('keydown', handleKeyPressed, true);

    return () => {
      window.removeEventListener('keydown', handleKeyPressed, true);
    };
  });

  function handleOnOverlayClick() {
    hideModal();
  }

  function handleOnDialogClick(e) {
    e.stopPropagation();
  }

  function renderModal() {
    switch (modalType) {
      case modalConstants.MODAL_NEW_CASE:
        return <NewCaseModal data={metaData} />;
      case modalConstants.MODAL_NEW_ADDRESS:
        return <NewAddressModal data={metaData} />;
      case modalConstants.MODAL_DELETE_CASE:
        return <ConfirmDeleteModal />;
      case modalConstants.MODAL_DELETE_TRANSACTION:
        return <ConfirmDeleteModal />;
      case modalConstants.MODAL_DELETE_ADDRESS:
        return <ConfirmDeleteModal />;
      case modalConstants.MODAL_SANCTION_DETAILS:
        return <SanctionDetailsModal />;
      default:
        return null;
    }
  }

  const modal = isOpen ? (
    <FlexLayout modalOverlay={true} onClick={handleOnOverlayClick} alignItems="center" justifyContent="center">
      <FlexLayout onClick={handleOnDialogClick} sx={{ width: width, bg: 'body' }} flexDirection="column">
        <Card
          title={title}
          titleVariant="heading-semibold-s"
          isTitleUppercase={false}
          rightText="CLOSE"
          rightTextVariant="label-semibold-m"
          rightTextColor="grey-400"
          icon="close"
          variant="primary"
          borderRadius="m"
          width="fit-content"
          p={6}
          onClick={() => {
            hideModal();
          }}
        >
          {renderModal()}
        </Card>
      </FlexLayout>
    </FlexLayout>
  ) : null;

  return ReactDOM.createPortal(modal, modalRoot);
}

export default Modal;
