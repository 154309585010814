import React from 'react';
import PT from 'prop-types';

import { NewAddressForm } from '~/components';

function NewAddressModal(props) {
  return <NewAddressForm {...props} />;
}

NewAddressModal.propTypes = {
  data: PT.any,
};

export default NewAddressModal;
