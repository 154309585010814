import React from 'react';
import ReactDOM from 'react-dom';
import colors from './colors';
import { ThemeProvider } from 'theme-ui';
import { FlexLayout, theme, Text } from '~/ui';
import mapboxgl from 'mapbox-gl';

const drawAddress = (map, address, color = colors.ADDRESS.BLUE) => {
  const {
    id,
    name,
    plotRadius: radius,
    address: {
      lat: geoLat,
      lng: geoLng,
      geoData: {
        data: {
          formatted:  place_name,
        },
      },
    },
  } = address;
  const geoCoordinates = [geoLng, geoLat];
  const sourceLayer = map.addSource(`address-${id}`, makeMarkerSource(place_name, geoCoordinates));

  const circleLayer = map.addLayer({
    id: `address-${id}-circle`,
    type: 'circle',
    source: `address-${id}`,
    paint: {
      'circle-color': color.CIRCLE,
      'circle-radius': {
        stops: zoomLevelRadiuses(radius, geoLat),
        base: 2,
      },
      'circle-stroke-width': 2,
      'circle-stroke-color': color.STROKE,
      'circle-radius-transition': {
        duration: 150,
      },
    },
  });

  const pointLayer = map.addLayer({
    id: `address-${id}-point`,
    type: 'circle',
    source: `address-${id}`,
    paint: {
      'circle-color': color.POINT,
      'circle-radius': 6,
    },
  });

  let popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on('mouseenter', `address-${id}-point`, (e) => {
    map.getCanvas().style.cursor = 'pointer';

    updateAddressColor(map, id, colors.ADDRESS.YELLOW);

    const placeholder = document.createElement('div');
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <FlexLayout
          p={6}
          space={2}
          flexDirection="column"
          variant="primary"
          sx={{
            visibility: 'visible',
            backgroundColor: 'body',
            border: 'polar-border',
            borderRadius: 'm',
          }}
        >
          <Text variant="label-m" color="white">
            {name}
          </Text>
          <Text variant="label-m" color="grey-400">
            {place_name}
          </Text>
        </FlexLayout>
      </ThemeProvider>,
      placeholder,
    );

    popup.setDOMContent(placeholder).setLngLat(e.lngLat).addTo(map);
  });

  map.on('mouseleave', `address-${id}-point`, () => {
    map.getCanvas().style.cursor = '';
    popup.remove();

    updateAddressColor(map, id, colors.ADDRESS.BLUE);
  });

  return {
    type: 'address',
    remove: (id) => {
      map.removeLayer(`address-${id}-point`);
      map.removeLayer(`address-${id}-circle`);
      map.removeSource(`address-${id}`);
    },
  };
};

const zoomLevelRadiuses = (radius, lat) => {
  return [
    [0, metersToPixelsAtMaxZoom(radius, lat, 0)],
    [1, metersToPixelsAtMaxZoom(radius, lat, 1)],
    [2, metersToPixelsAtMaxZoom(radius, lat, 2)],
    [3, metersToPixelsAtMaxZoom(radius, lat, 3)],
    [4, metersToPixelsAtMaxZoom(radius, lat, 4)],
    [5, metersToPixelsAtMaxZoom(radius, lat, 5)],
    [6, metersToPixelsAtMaxZoom(radius, lat, 6)],
    [7, metersToPixelsAtMaxZoom(radius, lat, 7)],
    [8, metersToPixelsAtMaxZoom(radius, lat, 8)],
    [9, metersToPixelsAtMaxZoom(radius, lat, 9)],
    [10, metersToPixelsAtMaxZoom(radius, lat, 10)],
    [11, metersToPixelsAtMaxZoom(radius, lat, 11)],
    [12, metersToPixelsAtMaxZoom(radius, lat, 12)],
    [13, metersToPixelsAtMaxZoom(radius, lat, 13)],
    [14, metersToPixelsAtMaxZoom(radius, lat, 14)],
    [15, metersToPixelsAtMaxZoom(radius, lat, 15)],
    [16, metersToPixelsAtMaxZoom(radius, lat, 16)],
    [17, metersToPixelsAtMaxZoom(radius, lat, 17)],
    [18, metersToPixelsAtMaxZoom(radius, lat, 18)],
    [19, metersToPixelsAtMaxZoom(radius, lat, 19)],
    [20, metersToPixelsAtMaxZoom(radius, lat, 20)],
    [21, metersToPixelsAtMaxZoom(radius, lat, 21)],
    [22, metersToPixelsAtMaxZoom(radius, lat, 22)],
  ];
};
const metersToPixelsAtMaxZoom = (radiusInMeters, latitude, zoom) => {
  return radiusInMeters / (78271.484 / 2 ** zoom) / Math.cos((latitude * Math.PI) / 180);
};

export const updateRadius = (map, address) => {
  const {
    id,
    plotRadius,
    address: { lat },
  } = address;

  map.setPaintProperty(`address-${id}-circle`, 'circle-radius', {
    stops: zoomLevelRadiuses(plotRadius, lat),
    base: 2,
  });
};

export const updateAddressColor = (map, addressId, newColor = colors.ADDRESS.BLUE) => {
  map.setPaintProperty(`address-${addressId}-point`, 'circle-color', newColor.POINT);
  map.setPaintProperty(`address-${addressId}-circle`, 'circle-color', newColor.CIRCLE);
  map.setPaintProperty(`address-${addressId}-circle`, 'circle-stroke-color', newColor.STROKE);
};

const makeMarkerSource = (description, coordinates) => {
  return {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {
        description,
      },
      geometry: {
        type: 'Point',
        coordinates,
      },
    },
  };
};

export default drawAddress;
