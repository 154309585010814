import React, { useState, useRef } from 'react';
import { FlexLayout, Text, Button, Card, Checkbox } from '~/ui';
import statuses from '~/constants/caseStatusType';
import PT from 'prop-types';
import { useOnClickOutside } from '~/hooks';

function StatusFilters({ setStatusFilters, setShowStatusFilter, statusFilters = [] }) {
  const [selected, setSelected] = useState(statusFilters);

  const handleSelect = (status) => {
    setSelected(
      selected.findIndex((s) => s === status) < 0 ? selected.concat([status]) : selected.filter((s) => s !== status),
    );
  };

  return (
    <FlexLayout
      flexDirection="column"
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{ zIndex: 1 }}
    >
      <Card
        title="Filter by Status"
        titleVariant="heading-semibold-s"
        isTitleUppercase={false}
        rightText="CLOSE"
        rightTextVariant="label-semibold-m"
        rightTextColor="grey-400"
        icon="close"
        variant="primary"
        borderRadius="m"
        padding="ml"
        margin="l"
        onClick={() => setShowStatusFilter(false)}
        sx={{
          position: 'absolute',
          right: '0px',
          top: '36px',
          width: '374px',
          border: 'border-input',
        }}
      >
        <FlexLayout flexDirection="column" justifyContent="center" space={5} mb={10}>
          {statuses.map((status) => (
            <Checkbox
              key={status.label}
              label={status.label}
              isActive={selected.findIndex((value) => value === status.enum) >= 0}
              onChange={() => handleSelect(status.enum)}
              border="white"
              height="icon-s"
              width="icon-s"
            >
              <Text
                sx={{
                  backgroundColor: status.color,
                  height: 'icon-xxs',
                  width: 'icon-xxs',
                  borderRadius: 'm',
                }}
                mr={2}
                textColor={status.color}
              >
                &nbsp;&nbsp;
              </Text>
            </Checkbox>
          ))}
        </FlexLayout>
        <FlexLayout justifyContent="space-between" space={6}>
          <Button
            text="Reset filters"
            textVariant="heading-s"
            textColor="yellow"
            variant="secondary"
            size="normal"
            sx={{ border: 'yellow', width: 'button-width', height: 'button-height' }}
            onClick={() => {
              setStatusFilters(['Active', 'Pending']);
              setShowStatusFilter(false);
            }}
          />
          <Button
            text="Apply"
            textVariant="heading-s"
            textColor="text-body"
            variant="third"
            size="normal"
            sx={{ width: 'button-width', height: 'button-height' }}
            onClick={() => {
              setStatusFilters(selected);
              setShowStatusFilter(false);
            }}
          />
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
}

StatusFilters.propTypes = {
  setShowStatusFilter: PT.func,
  statusFilters: PT.array,
  setStatusFilters: PT.func,
};

export default StatusFilters;
