const modalConstants = {
  MODAL_NEW_CASE: 'modal_new_case',
  MODAL_NEW_ADDRESS: 'modal_new_address',
  MODAL_DELETE_CASE: 'modal_delete_case',
  MODAL_DELETE_TRANSACTION: 'modal_delete_transaction',
  MODAL_DELETE_ADDRESS: 'modal_delete_address',
  MODAL_SANCTION_DETAILS: 'modal_sanction_details',
};

export const modalTitles = {
  modal_new_case: 'New Case',
  modal_new_address: 'New Address',
  modal_delete_case: 'Delete Case',
  modal_delete_transaction: 'Delete Transaction',
  modal_delete_address: 'Delete Address',
  modal_sanction_details: 'Sanction Details',
};

export default modalConstants;
