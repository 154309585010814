import colors from './colors';

export default {
  'border-input': `1px solid ${colors.palette['polar-grey-blue']}`,
  white: `1px solid ${colors.palette['white']}`,
  yellow: '1px solid yellow',
  header: `1px solid ${colors.palette['grey']}`,
  dark: `1px solid ${colors.palette['dark']}`,
  'polar-border': `1px solid ${colors.palette['polar-blue']}`,
};
