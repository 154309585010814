import React, { Fragment, useEffect, useState } from 'react';
import PT from 'prop-types';
import { range } from 'lodash';

import { paginationConstants } from '~/constants';
import FlexLayout from '../FlexLayout';
import { Icon, Text } from '..';
import colors from '~/ui/theme/colors';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const Pagination = ({
  totalRecords = null,
  pageLimit = paginationConstants.PAGE_LIMIT,
  pageNeighbours = 0,
  onPageChanged,
  startingPage = 1,
  textVariant = 'heading-s',
  height = '35px',
  width = '34px',
  sx = {},
}) => {
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(startingPage);
  const [hoveredPage, setHoveredPage] = useState(null);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageLimit));
  });

  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage + 1);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset + 1);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages + 1);
  };

  const gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    onPageChanged(currentPage);
    setCurrentPage(currentPage);
  };

  const pages = fetchPageNumbers();

  const handleClick = (page) => (evt) => {
    evt.preventDefault();
    if (page > Math.ceil(totalRecords / pageLimit)) gotoPage(1);
    else {
      gotoPage(page);
    }
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  return (
    <FlexLayout alignItems="center" justifyContent="center" space={2} sx={{ ...sx }}>
      {pages.map((page, index) => {
        if (page === LEFT_PAGE)
          return (
            <FlexLayout key={index} sx={{ cursor: 'pointer' }}>
              <Icon icon="chevronDown" sx={{ transform: `rotate(90deg)` }} onClick={handleMoveLeft} />
            </FlexLayout>
          );

        if (page === RIGHT_PAGE)
          return (
            <FlexLayout key={index} sx={{ cursor: 'pointer' }}>
              <Icon icon="chevronDown" sx={{ transform: `rotate(-90deg)` }} onClick={handleMoveRight} />
            </FlexLayout>
          );

        return (
          <FlexLayout
            key={index}
            sx={{
              height,
              width,
              background:
                page === currentPage
                  ? colors.alphas['grey-100']
                  : page === hoveredPage
                  ? colors.palette['polar-blue']
                  : null,
              borderRadius: 'm',
              cursor: 'pointer',
            }}
            alignItems="center"
            justifyContent="center"
            onMouseEnter={() => setHoveredPage(page)}
            onMouseLeave={() => setHoveredPage(null)}
            onClick={handleClick(page)}
          >
            <Text
              key={index}
              variant={textVariant}
              color={page === currentPage ? 'dark-blue' : page === hoveredPage ? 'white' : 'grey-500'}
            >
              {page}
            </Text>
          </FlexLayout>
        );
      })}
    </FlexLayout>
  );
};

Pagination.propTypes = {
  totalRecords: PT.number.isRequired,
  pageLimit: PT.number,
  pageNeighbours: PT.oneOf([0, 1, 2]),
  onPageChanged: PT.func,
  height: PT.string,
  width: PT.string,
  startingPage: PT.number,
  textVariant: PT.string,
  sx: PT.any,
};

export default Pagination;
