import { VirturisApi } from '~/apis';

function createAddress(body) {
  return VirturisApi.post('/case-address/create', body);
}

function updateAddress(id, body) {
  return VirturisApi.patch(`/case-address/${id}`, body);
}

function deleteAddress(id) {
  return VirturisApi.delete(`/case-address/${id}`);
}

const addressesService = {
  createAddress,
  updateAddress,
  deleteAddress,
};

export default addressesService;
