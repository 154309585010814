import React from 'react';
import PT from 'prop-types';

import { FlexLayout, Icon, Text } from '~/ui';

import { withHover } from '~/hocs';

const HoverableIcon = ({
  isHovered,
  eventHandlers,
  tooltipWidth,
  left,
  top,
  onClick,
  popupText,
  icon,
  iconSize,
  iconSx,
}) => {
  return (
    <FlexLayout
      sx={{ position: 'relative', cursor: 'pointer', height: '24px', width: '24px' }}
      onClick={onClick}
      {...eventHandlers}
    >
      <Icon icon={icon} iconSize={iconSize} color={isHovered ? 'yellow' : 'grey-500'} sx={iconSx} />
      {isHovered && (
        <FlexLayout
          variant="primary"
          sx={{
            backgroundColor: 'body',
            border: 'polar-border',
            borderRadius: 'm',
            position: 'absolute',
            zIndex: 2,
            width: tooltipWidth,
            top,
            left,
          }}
          px={3}
          py={2}
        >
          <Text variant="label-s" color="white">
            {popupText}
          </Text>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

HoverableIcon.propTypes = {
  isHovered: PT.bool,
  onClick: PT.func,
  top: PT.number,
  left: PT.number,
  tooltipWidth: PT.string,
  popupText: PT.string,
  eventHandlers: PT.object,
  icon: PT.string,
  iconSize: PT.string,
  iconSx: PT.object,
};

export const IconWithHover = (props) => withHover(HoverableIcon, props);
