import React from 'react';
import PT from 'prop-types';

import { FlexLayout, Text, Checkbox } from '~/ui';
import { EditDeletePopup } from '~/components';
import { modalConstants } from '~/constants';
import { addressesService } from '~/services';
import { useModal } from '~/hooks';

const Addresses = ({
  data: { addresses, renderedObjects, caseData },
  meta: { showAddress, hideAddress, setCaseDetails, setAddresses, setRenderedObjects },
  map,
}) => {
  const { showModal } = useModal();

  const editAddress = (data) => {
    if (data.plotted) {
      setRenderedObjects((prev) => {
        if (prev[data.id]) {
          prev[data.id].address = data;
          return { ...prev };
        }
      });
    }
    setCaseDetails((prev) => {
      const index = prev.caseAddresses.findIndex((address) => address.id === data.id);
      prev.caseAddresses[index] = data;
      return { ...prev, caseAddresses: [...prev.caseAddresses] };
    });
  };

  const deleteAddress = (id) => {
    addressesService.deleteAddress(id).then(({ data, error }) => {
      if (!error) {
        const addressId = data;
        let tmp = [...addresses];
        const addressIndex = tmp.findIndex((address) => (address.id = addressId));
        if (tmp[addressIndex].plotted) {
          hideAddress(map, tmp[addressIndex]);
        }
        tmp.splice(addressIndex, 1);
        setCaseDetails((prev) => {
          return { ...prev, caseAddresses: tmp };
        });
      }
    });
  };

  return addresses && addresses.length > 0 ? (
    <FlexLayout flexDirection="column" sx={{ borderRadius: 'm' }}>
      <FlexLayout
        bg="grey"
        p={2}
        space={6}
        sx={{ height: '24px', borderTopRightRadius: 'm', borderTopLeftRadius: 'm' }}
        alignItems="center"
      >
        <Text variant="label-xs" color="grey-400">
          Plot
        </Text>
        <Text variant="label-xs" color="grey-400" ml={4}>
          Address
        </Text>
      </FlexLayout>
      {addresses.map((address, index) => {
        return (
          <FlexLayout
            key={address.id}
            bg="grey"
            px={2}
            py={3}
            mt="1px"
            sx={{
              position: 'relative',
              width: '100%',
              borderBottomLeftRadius: index == addresses.length - 1 ? 'm' : null,
              borderBottomRightRadius: index == addresses.length - 1 ? 'm' : null,
            }}
            alignItems="center"
          >
            <FlexLayout alignItems="center" flexGrow="1" space={4} mt="1px">
              <Checkbox
                border={null}
                isActive={address.plotted}
                onChange={() => {
                  if (!address.plotted) {
                    showAddress(map, address);
                  } else {
                    addressesService
                      .updateAddress(address.id, { plotted: false, name: address.name })
                      .then(({ data, error }) => {
                        if (!error) {
                          hideAddress(map, data);
                        }
                      });
                  }
                }}
                height="icon-m"
                width="icon-m"
                sx={{ mr: '24px' }}
              />
              <FlexLayout flexDirection="column" justifyContent="center" sx={{ width: '240px' }}>
                <Text variant="label-m" color="white">
                  {address.name}
                </Text>
                <Text variant="label-xs" color="grey-400">
                  {address.address.geoData.formatted}
                </Text>
              </FlexLayout>
            </FlexLayout>
            <EditDeletePopup
              sx={{
                top: 1,
                left: 8,
                zIndex: 1,
              }}
              dotsColor="grey-500"
              iconSize="s"
              options={[
                {
                  icon: 'pencil',
                  label: 'Edit',
                  onClick: () => {
                    showModal(modalConstants.MODAL_NEW_ADDRESS, editAddress, {
                      name: address.address.geoData.query,
                      addressName: address.name,
                      id: address.id,
                    });
                  },
                },
                {
                  icon: 'delete',
                  label: 'Delete',
                  onClick: () => {
                    showModal(modalConstants.MODAL_DELETE_ADDRESS, deleteAddress, { id: address.id });
                  },
                },
              ]}
            />
          </FlexLayout>
        );
      })}
    </FlexLayout>
  ) : null;
};

const AddressShape = PT.shape({
  address: PT.string,
  addressNmae: PT.string,
});

Addresses.propTypes = {
  data: PT.shape({ addresses: PT.array, renderedObjects: PT.object, caseData: PT.any }),
  meta: PT.shape({
    showAddress: PT.func,
    hideAddress: PT.func,
    setCaseDetails: PT.func,
    setAddresses: PT.func,
    setRenderedObjects: PT.func,
  }),
  map: PT.any,
};

export default Addresses;
