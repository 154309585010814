import React from 'react';

import { Box, Button, FlexLayout, Text } from '~/ui';
import { useModal } from '~/hooks';
import { modalTitles } from '~/constants/modalConstants';

const ConfirmDeleteForm = () => {
  const { hideModal, title, metaData } = useModal();
  return (
    <Box
      as="form"
      onSubmit={(event) => {
        event.preventDefault();
        if (title === modalTitles.modal_delete_transaction) {
          hideModal(metaData);
        } else {
          hideModal(metaData.id);
        }
      }}
    >
      <FlexLayout flexDirection="column" space={6}>
        <Text variant="label-xl" color="grey-400">
          Are you sure you want to delete this {title.split(' ')[1].toLowerCase()}? This action can’t be undone.
        </Text>
        <Button
          variant="danger"
          text={title}
          type="submit"
          textVariant="heading-semibold-s"
          width="fullWidth"
          textColor="white"
          size="normal"
        />
      </FlexLayout>
    </Box>
  );
};

export default ConfirmDeleteForm;
