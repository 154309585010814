import React, { useEffect } from 'react';
import { columns } from './colums';
import { Helmet } from 'react-helmet';

import parse from 'html-react-parser/dist/html-react-parser';

import PT from 'prop-types';

import { FlexLayout, Text, Button, Table, Input, Box } from '~/ui';
import { formatInlineDateUser } from '~/utils';

function InvestigationDetails({
  data: { caseDetails },
  meta: {
    documentRef,
    onDocumentSubmit,
    attachDocument,
    setDocuments,
    setEditInProgress,
    updateCase,
    editInProgress,
    user,
  },
}) {
  return (
    <FlexLayout sx={{ width: 'page-width' }} py={10} flexDirection="column">
      <Helmet>
        <title>{caseDetails.name} Details | Virturis</title>
      </Helmet>
      <FlexLayout flexDirection="column" mb={16}>
        <Text variant="heading-semibold-l" mb={16}>
          Details
        </Text>
        <Text variant="heading-semibold-m" mb={6}>
          Case Summary
        </Text>
        {caseDetails.summary ? (
          <Box as="div" sx={{ fontFamily: '"DIN 2014"' }}>
            {parse(caseDetails.summary)}
          </Box>
        ) : (
          <Text variant="label-l" color="grey-500">
            You haven’t specified a summary for this case.
          </Text>
        )}
        {caseDetails.summaryLastUpdatedAt && caseDetails.summaryLastUpdatedAt != '' && (
          <FlexLayout flexDirection="row">
            <Text variant="label-l" color="grey-400">
              {formatInlineDateUser(user, caseDetails.summaryLastUpdatedAt)}
            </Text>
          </FlexLayout>
        )}
      </FlexLayout>
      <FlexLayout justifyContent="space-between" mb={7}>
        <Text variant="heading-semibold-m">Case Summary</Text>
        <Box as="form" onChange={onDocumentSubmit}>
          <Button
            text="Attach Document"
            textVariant="label-l"
            width="fitContent"
            textColor="text-body"
            iconColor="text-body"
            variant="third"
            size="normal"
            left={true}
            icon="plusNoCircle"
            onClick={attachDocument}
          ></Button>
          <Input ref={documentRef} type="file" name="file" displayNone={true} />
        </Box>
      </FlexLayout>
      {caseDetails.documents ? (
        <Table
          data={caseDetails.documents}
          columns={columns(caseDetails.documents, setDocuments)}
          WrapperComponent={null}
        />
      ) : null}
    </FlexLayout>
  );
}

const documentShape = PT.shape({
  id: PT.string,
  createdAt: PT.string,
  updatedAt: PT.string,
  mimeType: PT.string,
  name: PT.string,
  size: PT.number,
});

InvestigationDetails.propTypes = {
  data: PT.shape({
    caseDetails: PT.object,
  }),
  meta: PT.shape({
    setRenderedObjects: PT.func,
    documentRef: PT.any,
    onDocumentSubmit: PT.func,
    attachDocument: PT.func,
    setDocuments: PT.func,
    editInProgress: PT.bool,
    user: PT.object,
    setEditInProgress: PT.func,
    updateCase: PT.func,
  }),
};

export default InvestigationDetails;
