import React, { useRef } from 'react';
import PT from 'prop-types';
import { components } from 'react-select';

import { Icon, Box, FlexLayout } from '~/ui';
import { useOnClickOutside } from '~/hooks';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="chevronDown" />
    </components.DropdownIndicator>
  );
}

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <FlexLayout
        onClick={(event) => {
          props.selectProps.onClick(true);
        }}
        sx={{ cursor: 'pointer', width: '100%' }}
      >
        {children}
      </FlexLayout>
    </components.Control>
  );
};

Control.propTypes = {
  children: PT.array,
  selectProps: PT.object,
};

const ValueContainer = ({ children, ...props }) => {
  const selectValue = props.getValue()[0];

  return (
    <components.ValueContainer {...props}>
      <FlexLayout alignItems={'center'} sx={{ width: 'full-div' }} space={2}>
        {selectValue.color ? (
          <Box sx={{ borderRadius: 'm', bg: selectValue.color, width: '12px', height: '12px' }}></Box>
        ) : null}
        {children}
      </FlexLayout>
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PT.array,
  getValue: PT.func,
};

function IndicatorSeparator() {
  return null;
}

const Option = ({ children, ...props }) => {
  const value = props.data;
  return (
    <components.Option {...props}>
      <FlexLayout alignItems="center" sx={{ height: 'input-height' }}>
        {value.color ? (
          <Box sx={{ borderRadius: 'm', bg: value.color, width: '12px', height: '12px', marginRight: 2 }} />
        ) : null}
        {children}
      </FlexLayout>
    </components.Option>
  );
};

Option.propTypes = {
  children: PT.array,
  data: PT.object,
};

const Menu = ({ children, ...props }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => props.selectProps.onClick(false));

  return (
    <components.Menu {...props}>
      <FlexLayout ref={ref}>{children}</FlexLayout>
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PT.array,
  selectProps: PT.object,
};

const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList {...props}>
      <FlexLayout flexDirection="column" space={4} p={6} sx={{ maxHeight: '100%' }}>
        {children}
      </FlexLayout>
    </components.MenuList>
  );
};

MenuList.propTypes = {
  children: PT.array,
};

export default { DropdownIndicator, IndicatorSeparator, ValueContainer, MenuList, Option, Menu, Control };
