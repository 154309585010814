import { VirturisApi } from '~/apis';

function getSanctions(body) {
  return VirturisApi.post('/sanctions/get', body);
}

const sanctionsService = {
  getSanctions,
};

export default sanctionsService;
