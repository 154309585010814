import PT from 'prop-types';
import React from 'react';

import Box from '../Box';

const modalOverlayStyles = {
  position: 'fixed',
  right: 0,
  bottom: 0,
  left: 0,
  bg: 'rgba(32, 34, 37, 0.8)',
  height: '100%',
};

const FlexLayout = React.forwardRef(
  (
    {
      alignItems,
      children,
      flex,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      justifyContent,
      space = 0,
      modalOverlay = false,
      sx = {},
      ...rest
    },
    ref,
  ) => {
    const marginDirection = flexDirection === 'column' ? 'mb' : 'mr';

    let styles = {
      alignItems,
      display: 'flex',
      flex,
      flexDirection,
      flexGrow: flexGrow,
      flexShrink,
      flexWrap,
      justifyContent,
      ...sx,
    };
    if (space !== 0) {
      styles['> :not(:last-child)'] = {
        [marginDirection]: space,
      };
    }

    if (modalOverlay) {
      styles = { ...styles, ...modalOverlayStyles };
    }

    return (
      <Box ref={ref} sx={{ ...styles }} {...rest}>
        {children}
      </Box>
    );
  },
);

FlexLayout.propTypes = {
  alignItems: PT.string,
  children: PT.node,
  flex: PT.string,
  flexDirection: PT.string,
  flexGrow: PT.string,
  flexShrink: PT.string,
  flexWrap: PT.string,
  justifyContent: PT.string,
  space: PT.oneOfType([PT.number, PT.string]),
  sx: PT.object,
  modalOverlay: PT.bool,
};

FlexLayout.displayName = 'FlexLayout';

export default FlexLayout;
