import axios from 'axios';
import config from 'config';

const openCorporatesService = (page, limit, searchQuery) => {
  return axios.get(
    `${config.opencorporates_api_endpoint}/companies/search?q=&registered_address=${searchQuery}&api_token=${config.opencorporates_api_token}&page=${page}&per_page=${limit}`,
  );
};

export default openCorporatesService;
