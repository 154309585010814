import React from 'react';
import PT from 'prop-types';

import { Box } from '~/ui';
import Option from './Option';

const Dropdown = ({ options, sx, ...rest }) => {
  return (
    <Box
      bg="body"
      sx={{
        ...sx,
        borderRadius: 'm',
        position: 'absolute',
        right: 0,
        zIndex: 1,
        p: 6,
        width: '192px',
        border: 'polar-border',
        justifyContent: 'space-between',
      }}
      {...rest}
    >
      {options.map((props, index) => (
        <Option key={index} {...props} />
      ))}
    </Box>
  );
};

Dropdown.propTypes = {
  options: PT.array,
  sx: PT.object,
};

export default Dropdown;
