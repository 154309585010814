import React from 'react';
import PT from 'prop-types';

import { ConfirmDeleteForm } from '~/components';

function ConfirmDeleteModal({ data }) {
  return <ConfirmDeleteForm data={data} />;
}

ConfirmDeleteModal.propTypes = {
  data: PT.any,
};

export default ConfirmDeleteModal;
