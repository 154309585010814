import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';
import PT from 'prop-types';
import { withDropdown } from '~/hocs';

const EditDeletePopup = ({ dotsColor, iconSize, sx, ...rest }) => {
  return (
    <FlexLayout {...rest} flexDirection="column" sx={{ cursor: 'pointer' }}>
      <Icon icon="dots" size={iconSize} color={dotsColor} />
    </FlexLayout>
  );
};

EditDeletePopup.propTypes = {
  dotsColor: PT.string,
  iconSize: PT.string,
  sx: PT.object,
};

export default withDropdown(EditDeletePopup);
