const colors = {
  ADDRESS: {
    BLUE: {
      CIRCLE: 'rgba(10, 77, 245, 0.04)',
      STROKE: 'rgba(10, 77, 245, 0.4)',
      POINT: 'rgb(10, 77, 245)',
    },
    YELLOW: {
      CIRCLE: 'rgba(255, 214, 0, 0.04)',
      STROKE: 'rgba(255, 214, 0, 0.2)',
      POINT: '#FFD600',
    },
  },
  LINE: {
    BLUE: '#0A39B0',
    YELLOW: '#F0CA00',
  },
  SANCTIONS: {
    RED: '#AF1524',
    YELLOW: '#FFD600',
  },
};

export default colors;
