import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FlexLayout, Box, Text, Input } from '~/ui';
import { sessionService } from '~/services';
import { checkPassword } from '~/utils';

function SignupForm() {
  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
  });
  const [error, setError] = useState(false);

  function onSubmit(data) {
    const { email, password, fullName } = data;
    if (checkPassword(password, setError)) {
      let nameArray = fullName.split(' ');
      sessionService.register({ email, password, firstName: nameArray[0], lastName: nameArray[1] }).then(() => {
        setError(false);
        reset();
      });
    }
  }

  return (
    <FlexLayout flexDirection="column">
      <Text variant="paragraph-m" mb={4}>
        SIGN UP
      </Text>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FlexLayout mb={3} space={4} flexDirection="column">
          <Input
            control={control}
            icon="person"
            type="text"
            color="white"
            name="fullName"
            placeholder="First and Last name"
          />
          <Input control={control} icon="envelope" color="white" type="text" name="email" placeholder="Email address" />
          <Input control={control} icon="lock" color="white" type="password" name="password" placeholder="Password" />
        </FlexLayout>
        <Text variant="label-s" mb={3} color="grey-400">
          Your password needs to contain at least
          <Box as="span" color={error ? 'yellow' : 'grey-400'}>
            {' '}
            8 characters, a mixture of numbers, letters and special characters
          </Box>
          .
        </Text>
        <Button text="Sign Up" width="medium" variant="primary" type="submit" textVariant="label-s" icon="arrowRight" />
      </Box>
    </FlexLayout>
  );
}

export default SignupForm;
