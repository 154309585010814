import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { Helmet } from 'react-helmet';

import { FlexLayout } from '~/ui';

import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import mapboxgl from 'mapbox-gl';
import config from '~/config';
import { fitAll } from '~/utils';

function Investigation({ data, meta }) {
  const [map, setMap] = useState(undefined);
  useEffect(() => {
    mapboxgl.accessToken = config.mapbox_token;
    let tmpMap = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [-74.5, 40],
      zoom: 3,
    });
    tmpMap.on('load', () => {
      tmpMap.addSource('transparent', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-180, 90],
                [-180, -90],
                [180, 90],
                [180, -90],
              ],
            ],
          },
        },
      });
      tmpMap.addLayer({
        id: 'transparent-layer',
        type: 'fill',
        source: 'transparent',
        layout: {
          visibility: 'none',
        },
        paint: {
          'fill-opacity': 0,
        },
      });
      let tmpRenderedObjects = meta.showTransactionObjects(tmpMap, data.caseDetails.transactions);
      tmpRenderedObjects = meta.showAddressObjects(tmpMap, data.addresses, tmpRenderedObjects, false);
      if (data.caseDetails.plottedRiskKeys.sanctions) {
        meta.toggleSanctions(tmpMap, tmpRenderedObjects, undefined, true);
      }
    });

    setMap(tmpMap);

    return () => {
      tmpMap.remove();
      meta.setRenderedObjects({});
    };
  }, []);

  useEffect(() => {
    fitAll(map, meta.renderedObjects);
  }, [meta.renderedObjects]);

  return (
    <FlexLayout flexGrow="1">
      <Helmet>
        <title>{data.caseDetails.name} | Virturis</title>
      </Helmet>
      <FlexLayout
        bg="body"
        flexDirection="column"
        p={4}
        space={6}
        sx={{ width: '400px', flexShrink: '0', maxHeight: 'calc(100vh - 65px)' }}
      >
        {map ? (
          <LeftSidebar map={map} data={data} meta={{ ...meta }} showTransactionObjects={meta.showTransactionObjects} />
        ) : null}
      </FlexLayout>
      <FlexLayout id="map" flexGrow="1" sx={{ height: 'calc(100vh - 65px)', overflow: 'visible' }} />
      <FlexLayout
        flexDirection="column"
        p={4}
        sx={{
          width: '400px',
          flexShrink: '0',
          maxHeight: 'calc(100vh - 65px)',
          overflowY: 'scroll',
        }}
        bg="body"
        space={6}
      >
        {map ? <RightSidebar data={data} meta={meta} map={map} /> : null}
      </FlexLayout>
    </FlexLayout>
  );
}

Investigation.propTypes = {
  data: PT.objectOf(PT.any),
  meta: PT.any,
};

export default Investigation;
