import React, { useState, useEffect, useRef } from 'react';

import { EditorState, SelectionState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import { Box } from '~/ui';

import PT from 'prop-types';
import { convertToHTML } from 'draft-convert';
import { useInterval, useOnClickOutside } from '~/hooks';

const AUTOSAVE_INTERVAL = 3000;
export default function EditorContainer({ previousEditorState, onChange, borderBottom = false }) {
  const [editorState, setEditorState] = useState(
    previousEditorState
      ? EditorState.moveFocusToEnd(EditorState.createWithContent(stateFromHTML(previousEditorState)))
      : EditorState.createEmpty(),
  );
  const editorRef = useRef();
  const outsideRef = useRef();
  const [outside, setOutside] = useState(false);
  useOnClickOutside(outsideRef, () => setOutside(true));
  useInterval(() => updateSummary(), AUTOSAVE_INTERVAL, true);

  const setEditorRef = (ref) => {
    if (ref) ref.focus();
  };

  const updateSummary = () => {
    const summary = convertToHTML(editorState.getCurrentContent());
    onChange({ summary });
  };

  return (
    <Box
      as="div"
      sx={{
        backgroundColor: 'white',
        color: 'black',
        display: 'flex',
        flexGrow: 1,
        overflowY: 'scroll',
        borderBottomRightRadius: borderBottom && 'm',
        borderBottomLeftRadius: borderBottom && 'm',
        height: '100%',
        fontFamily: '"DIN 2014"',
      }}
      ref={outsideRef}
    >
      <Editor
        editorRef={setEditorRef}
        editorState={editorState}
        onEditorStateChange={(editorState) => {
          if (!outside) setEditorState(editorState);
        }}
        ref={editorRef}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
        }}
        preserveSelectionOnBlur={!outside ? true : false}
        onFocus={() => setOutside(false)}
        onBlur={() => {
          if (!outside) setEditorState((prev) => EditorState.moveFocusToEnd(prev));
        }}
      />
    </Box>
  );
}

EditorContainer.propTypes = {
  previousEditorState: PT.string,
  onChange: PT.func,
  borderBottom: PT.bool,
};
