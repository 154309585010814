import React from 'react';
import { useForm } from 'react-hook-form';

import { modalConstants, paginationConstants, statuses } from '~/constants';
import { useModal } from '~/hooks';
import { casesService } from '~/services';
import { Text, FlexLayout, Select, Button, Pill } from '~/ui';
import { formatDate } from '~/utils';

function renderHeader(title) {
  return (
    <Text variant="label-m" color="grey-300">
      {title}
    </Text>
  );
}

export const columns = (cases, addCase, paginationMeta, onPaginationChange) => {
  const handleDelete = (event, itemId) => {
    event.preventDefault();
    casesService
      .deleteCase(itemId)
      .then(({ data, error }) => {
        if (!error) {
          if (cases.length === 1) {
            onPaginationChange(1);
          } else {
            onPaginationChange(paginationMeta.currentPage);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return [
    {
      label: function header() {
        return renderHeader('ID');
      },
      accessor: 'id',
      width: 50,
      render: function cell(item) {
        return (
          <Text variant="label-m" color="grey-400">
            {item.id}
          </Text>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('CASE');
      },
      accessor: 'case',
      width: 250,
      render: function cell(item) {
        return (
          <FlexLayout flexDirection="column" space={2}>
            <Text variant="heading-semibold-s">{item.case}</Text>
          </FlexLayout>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('LABELS');
      },
      accessor: 'labels',
      width: 180,
      render: function cell(item) {
        return (
          <FlexLayout space={2} flexWrap="wrap" sx={{ marginTop: '-8px' }}>
            {item.labels.length > 0 ? (
              item.labels.map((label) => <Pill key={label.name} value={label.name} sx={{ marginTop: '8px' }} />)
            ) : (
              <Text variant="paragraph-m" color="grey-500">
                No labels.
              </Text>
            )}
          </FlexLayout>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('DATE CREATED');
      },
      accessor: 'createdAt',
      width: 100,
      render: function cell(item) {
        return formatDate(item.createdAt);
      },
    },
    {
      label: function header() {
        return renderHeader('LAST UPDATED');
      },
      accessor: 'updatedAt',
      width: 100,
      render: function cell(item) {
        return formatDate(item.updatedAt);
      },
    },
    {
      label: function header() {
        return renderHeader('STATUS');
      },
      accessor: 'status',
      width: 240,
      render: function cell(item) {
        return (
          <FlexLayout
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Select
              color="white"
              size="nopad"
              width="small"
              options={statuses}
              value={statuses.filter((object) => object.enum === item.status)[0]}
              onChange={(value) => {
                casesService.updateCase(item.uuid, { status: value.enum }).then(({ data, error }) => {
                  if (!error) addCase(data);
                });
              }}
              variant="grey"
              valuePad="0"
            />
          </FlexLayout>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('');
      },
      accessor: 'buttons',
      render: function cell(item) {
        const { showModal } = useModal();
        return (
          <FlexLayout
            sx={{ width: 'full-div' }}
            space={4}
            alignItems="center"
            onClick={(event) => event.preventDefault()}
          >
            <Button
              sx={{ px: 4 }}
              text="Edit"
              variant="primary"
              width="fitContent"
              textVariant="label-m"
              onClick={() => {
                showModal(modalConstants.MODAL_NEW_CASE, addCase, {
                  name: item.case,
                  description: item.description,
                  labels: item.labels.map((el) => {
                    return { value: el.id, label: el.name };
                  }),
                  status: statuses.find((status) => status.enum == item.status),
                  id: item.uuid,
                });
              }}
            />
            <Button
              sx={{ px: 4 }}
              text="Delete"
              variant="primary"
              width="fitContent"
              textVariant="label-m"
              onClick={(e) => showModal(modalConstants.MODAL_DELETE_CASE, (id) => handleDelete(e, id), { id: item.uuid })}
            />
          </FlexLayout>
        );
      },
    },
  ];
};
