import React, { Fragment, useState } from 'react';

import { Card, FlexLayout, Text, Icon, Tabs, Box } from '~/ui';
import PlottedAddresses from './PlottedAddresses';
import PT from 'prop-types';

//commented out is what is removed from display because it is not currently implemented

const RightSidebar = ({ data: { addresses }, meta, map }) => {
  // const [type, setType] = useState('Geo intelligence');
  // const [newsType, setNewsType] = useState('Twitter');

  // const changeType = (type) => {
  //   setType(type);
  // };

  // const changeNewsType = (type) => {
  //   setNewsType(type);
  // };

  return (
    <Fragment>
      <Text variant="label-l" color="white">
        Geo Intelligence
      </Text>
      <PlottedAddresses data={{ addresses }} meta={meta} map={map} />
      {/* <Tabs onChange={changeType}>
        {['Geo intelligence', 'About'].map((title) => {
          return <Box key={title} label={title} />;
        })}
      </Tabs>
      {type === 'Geo intelligence' && addresses && <PlottedAddresses data={{ addresses }} meta={meta} map={map} />}
      <Card title="Company Registry Data" icon="caretUp">
        <FlexLayout bg="grey" p={2} sx={{ borderRadius: 'm' }} space={2} flexDirection="column">
          <FlexLayout alignItems="center" space={2} py={1}>
            <Icon icon="infoCircle" />
            <Text variant="label-s">No data available</Text>
          </FlexLayout>
        </FlexLayout>
      </Card>
      <Card title="Land registry" icon="caretUp">
        <FlexLayout bg="grey" p={2} sx={{ borderRadius: 'm' }} space={2} flexDirection="column">
          <FlexLayout alignItems="center" space={2} py={1}>
            <Icon icon="infoCircle" />
            <Text variant="label-s">No data available</Text>
          </FlexLayout>
        </FlexLayout>
      </Card>
      <Tabs onChange={changeNewsType}>
        {['Twitter', 'News'].map((title) => {
          return <Box as="div" key={title} label={title} />;
        })}
      </Tabs> */}
    </Fragment>
  );
};

RightSidebar.propTypes = {
  data: PT.shape({
    addresses: PT.array,
  }),
  meta: PT.any,
  map: PT.any,
};

export default RightSidebar;
