const riskKeys = [
  { name: 'Adverse Media', icon: null, color: 'adverse-media' },
  { name: 'Sanctions', icon: null, color: 'sanctions' },
  { name: 'Politicaly Exposed Persons', icon: null, color: 'PEP' },
  { name: 'Offshore Holdings & Registrations', icon: null, color: 'offshore' },
  { name: 'Money Service Business', icon: null, color: 'money-business' },
  { name: 'Commercial & Places of Worship', icon: 'geoAlt', color: 'grey-300' },
  { name: 'Company Registry Data', icon: null, color: 'company-registry-data' },
  { name: 'ATM', icon: 'cash', color: 'grey-300' },
];

export default riskKeys;
