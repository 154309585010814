export const fonts = {
  din: '"Din 2014"',
  dinSemiBold: '"DIN 2014 Demi"',
};

const fontWeights = {
  normal: 'normal',
  medium: '500',
  semibold: '600',
  bold: '700',
};

const variants = {
  'heading-l': {
    fontFamily: fonts.din,
    fontSize: '36px',
    fontWeight: fontWeights.medium,
    lineHeight: '54px',
  },
  'heading-semibold-l': {
    fontFamily: fonts.din,
    fontSize: '36px',
    fontWeight: fontWeights.semibold,
    lineHeight: '54px',
  },
  'heading-m': {
    fontFamily: fonts.din,
    fontSize: '24px',
    fontWeight: fontWeights.medium,
    lineHeight: '40px',
  },
  'heading-semibold-m': {
    fontFamily: fonts.dinSemiBold,
    fontSize: '24px',
    lineHeight: '40px',
  },
  'heading-s': {
    fontFamily: fonts.din,
    fontSize: '18px',
    fontWeight: fontWeights.normal,
    lineHeight: '27px',
  },
  'heading-semibold-s': {
    fontFamily: fonts.dinSemiBold,
    fontSize: '18px',
    lineHeight: '27px',
  },
  'label-semibold-xl': {
    fontFamily: fonts.din,
    fontSize: '16px',
    fontWeight: fontWeights.semibold,
    lineHeight: '24px',
  },
  'label-xl': {
    fontFamily: fonts.din,
    fontSize: '16px',
    fontWeight: fontWeights.normal,
    lineHeight: '24px',
  },
  'label-l': {
    fontFamily: fonts.din,
    fontSize: '16px',
    fontWeight: fontWeights.normal,
    lineHeight: '20px',
  },
  'label-m': {
    fontFamily: fonts.din,
    fontSize: '14px',
    fontWeight: fontWeights.normal,
    lineHeight: '16px',
  },
  'label-semibold-m': {
    fontFamily: fonts.dinSemiBold,
    fontSize: '14px',
    lineHeight: '16px',
  },
  'label-s': {
    fontFamily: fonts.din,
    fontSize: '12px',
    fontWeight: fontWeights.normal,
    lineHeight: '16px',
  },
  'label-xs': {
    fontFamily: fonts.din,
    fontSize: '9px',
    fontWeight: fontWeights.normal,
    lineHeight: '12px',
  },
  'label-semibold-xs': {
    fontFamily: fonts.dinSemiBold,
    fontSize: '9px',
    lineHeight: '12px',
  },
  'paragraph-m': {
    fontFamily: fonts.din,
    fontSize: '16px',
    fontWeight: fontWeights.medium,
    lineHeight: '24px',
  },
  'paragraph-s': {
    fontFamily: fonts.din,
    fontSize: '16px',
    fontWeight: fontWeights.normal,
    lineHeight: '24px',
  },
};

export default {
  fonts,
  fontWeights,
  variants,
};
