import React from 'react';
import ReactDOM from 'react-dom';
import colors from './colors';

import { ThemeProvider } from 'theme-ui';
import { FlexLayout, theme, Text, Icon } from '~/ui';

import mapboxgl from 'mapbox-gl';

const drawSanctions = (map, sanctions, showModal) => {
  map.addSource(`sanctions`, {
    type: 'geojson',
    data: sanctions,
    cluster: true,
    clusterMaxZoom: 22, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  });

  const clusters = map.addLayer({
    id: `sanctions-cluster`,
    type: 'circle',
    source: `sanctions`,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'case',
        ['==', ['feature-state', 'hover'], false],
        colors.SANCTIONS.RED,
        ['==', ['feature-state', 'hover'], true],
        colors.SANCTIONS.YELLOW,
        colors.SANCTIONS.RED,
      ],
      'circle-radius': 10,
      // for dynamic radius based on point_count
      // [
      // 'step',
      // ['zoom'],
      // ['step', ['get', 'point_count'], 10, 25, 20, 50, 30, 100, 35],
      // 15,
      // ['step', ['get', 'point_count'], 10, 10, 12, 50, 15],
      // ],
    },
  });

  map.addLayer({
    id: 'sanctions-cluster-count',
    type: 'symbol',
    source: 'sanctions',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 10,
    },
    paint: {
      'text-color': '#ffffff',
    },
  });

  map.addLayer({
    id: 'sanctions-unclustered',
    type: 'circle',
    source: 'sanctions',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': [
        'case',
        ['==', ['feature-state', 'hover'], false],
        colors.SANCTIONS.RED,
        ['==', ['feature-state', 'hover'], true],
        colors.SANCTIONS.YELLOW,
        colors.SANCTIONS.RED,
      ],
      'circle-radius': 6,
    },
  });

  let popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  }).setMaxWidth('560px');

  let hoveredId;

  map.on('mouseenter', 'sanctions-unclustered', (e) => {
    map.getCanvas().style.cursor = 'pointer';

    const {
      name,
      DOB,
      countryOfBirth,
      nationality,
      passportDetails,
      concatenatedAddress,
      otherInformation,
      groupType,
      aliasType,
      regime,
      listedOn,
      lastUpdated,
      sanctionsList,
    } = JSON.parse(e.features[0].properties.metadata);

    hoveredId = e.features[0].properties.id;

    const placeholder = document.createElement('div');
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <FlexLayout
          p={6}
          space={2}
          flexDirection="column"
          variant="primary"
          sx={{
            visibility: 'visible',
            backgroundColor: 'body',
            border: 'polar-border',
            borderRadius: 'm',
            width: '560px',
          }}
          justifyContent="space-between"
          flexFlow="row nowrap"
        >
          {name && (
            <FlexLayout justifyContent="space-between">
              <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
                Name
              </Text>
              <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
                {name}
              </Text>
            </FlexLayout>
          )}

          {concatenatedAddress && (
            <FlexLayout justifyContent="space-between">
              <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
                Address
              </Text>
              <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
                {concatenatedAddress}
              </Text>
            </FlexLayout>
          )}
          {regime && (
            <FlexLayout justifyContent="space-between">
              <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
                Regime
              </Text>
              <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
                {regime}
              </Text>
            </FlexLayout>
          )}
          {sanctionsList && (
            <FlexLayout justifyContent="space-between">
              <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
                Sanctions List
              </Text>
              <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
                {sanctionsList}
              </Text>
            </FlexLayout>
          )}
          {otherInformation && (
            <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
              Aditional Information
            </Text>
          )}
          {otherInformation && (
            <Text variant="label-m" color="white">
              {otherInformation}
            </Text>
          )}
          <FlexLayout alignItems="center" mt={3}>
            <Icon icon="clickMe" />
            <Text variant="label-l" color="grey-500" ml={2}>
              Click on the sanction for more information.
            </Text>
          </FlexLayout>
        </FlexLayout>
      </ThemeProvider>,
      placeholder,
    );

    map.setFeatureState({ source: 'sanctions', id: hoveredId }, { hover: true });
    popup.setDOMContent(placeholder).setLngLat(e.lngLat).addTo(map);
  });

  map.on('mouseleave', 'sanctions-unclustered', () => {
    map.getCanvas().style.cursor = '';

    map.setFeatureState({ source: 'sanctions', id: hoveredId }, { hover: false });
    hoveredId = undefined;
    popup.remove();
  });

  map.on('click', 'sanctions-unclustered', (e) => {
    const metadata = JSON.parse(
      map.queryRenderedFeatures(e.point, {
        layers: ['sanctions-unclustered'],
      })[0].properties.metadata,
    );
    showModal([metadata]);
  });

  map.on('mouseenter', 'sanctions-cluster', (e) => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'sanctions-cluster', () => {
    map.getCanvas().style.cursor = '';
  });

  map.on('click', 'sanctions-cluster', (e) => {
    const cluster = map.queryRenderedFeatures(e.point, {
      layers: ['sanctions-cluster'],
    })[0];
    const clusterId = cluster.properties.cluster_id;
    const pointCount = cluster.properties.point_count;
    map.getSource('sanctions').getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
      showModal(features.map((feature) => feature.properties.metadata));
    });
  });

  const editSource = (newSanctions) => {
    map.getSource('sanctions').setData(newSanctions);
  };

  const removeLayers = () => {
    map.removeLayer('sanctions-unclustered');
    map.removeLayer('sanctions-cluster');
    map.removeLayer('sanctions-cluster-count');
    map.removeSource('sanctions');
  };

  return {
    removeLayers,
    editSource,
  };
};

export default drawSanctions;
