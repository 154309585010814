import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'theme-ui';
import { theme } from '~/ui';
import TransactionInformationTooltip from '~/components/Investigation/Investigation/LeftSidebar/TransactionInformationTooltip';

import colors from './colors';
import { updateAddressColor } from './drawAddress';

const drawTransactionLine = (map, transaction) => {
  const { beneficiaryAddress, originatorAddress, id: transactionId } = transaction;

  const {
    id: beneficiaryId,
    address: { lat: beneficiaryLat, lng: beneficiaryLng },
  } = beneficiaryAddress;
  const beneficiary = [Number(beneficiaryLng), Number(beneficiaryLat)];

  const {
    id: originatorId,
    address: { lat: originLat, lng: originLng },
  } = originatorAddress;

  const destination = [Number(originLng), Number(originLat)];

  let route = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [beneficiary, destination],
        },
      },
    ],
  };

  let lineDistance = turf.distance(beneficiary, destination);
  let arc = [];
  let steps = 500;

  arc.push(beneficiary);
  for (let i = 1; i < lineDistance; i += lineDistance / steps) {
    let segment = turf.along(route.features[0], i);
    arc.push(segment.geometry.coordinates);
  }

  arc.push(destination);
  route.features[0].geometry.coordinates = arc;

  const sourceLayer = map.addSource(`transaction-${transactionId}`, {
    type: 'geojson',
    data: route,
  });

  const transactionLayer = map.addLayer(
    {
      id: `transaction-layer-${transactionId}`,
      source: `transaction-${transactionId}`,
      type: 'line',
      paint: {
        'line-width': 3,
        'line-color': colors.LINE.BLUE,
      },
    },
    'transparent-layer',
  );
  let popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  }).setMaxWidth('480px');

  map.on('mouseenter', `transaction-layer-${transactionId}`, (e) => {
    map.getCanvas().style.cursor = 'pointer';

    map.setPaintProperty(`transaction-layer-${transactionId}`, 'line-color', colors.LINE.YELLOW);
    updateAddressColor(map, beneficiaryId, colors.ADDRESS.YELLOW);
    updateAddressColor(map, originatorId, colors.ADDRESS.YELLOW);

    const placeholder = document.createElement('div');
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <TransactionInformationTooltip sx={{ visibility: 'visible' }} transaction={transaction} />
      </ThemeProvider>,
      placeholder,
    );

    popup.setDOMContent(placeholder).setLngLat(e.lngLat).addTo(map);
  });

  map.on('mouseleave', `transaction-layer-${transactionId}`, () => {
    map.getCanvas().style.cursor = '';
    popup.remove();

    map.setPaintProperty(`transaction-layer-${transactionId}`, 'line-color', colors.LINE.BLUE);
    updateAddressColor(map, beneficiaryId, colors.ADDRESS.BLUE);
    updateAddressColor(map, originatorId, colors.ADDRESS.BLUE);
  });

  return {
    type: 'transaction',
    remove: () => {
      map.removeLayer(`transaction-layer-${transactionId}`);
      map.removeSource(`transaction-${transactionId}`);
    },
  };
};

export default drawTransactionLine;
