import PT from 'prop-types';
import React, { Children } from 'react';
import { NavLink } from 'react-router-dom';

import { Text, Icon, FlexLayout } from '~/ui';

const Link = React.forwardRef(({ title, icon, iconColor, path, state = {}, children }, ref) => {
  return (
    <NavLink exact to={{ pathname: path, state }} activeStyle={{ borderBottom: '1px solid yellow', height: '55px' }}>
      <FlexLayout space={2} py={4} alignItems="center">
        {icon ? <Icon icon={icon} iconColor={iconColor} /> : children}
        <Text variant="label-semibold-m" color="white" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Text>
      </FlexLayout>
    </NavLink>
  );
});

Link.propTypes = {
  path: PT.string,
  title: PT.string,
  icon: PT.string,
  iconColor: PT.string,
  children: PT.node,
  state: PT.any,
};

Link.displayName = 'Link';

export default Link;
