import React from 'react';
import PT from 'prop-types';

import { Text, FlexLayout } from '~/ui';

const ListWithLabels = ({ listItems, labelVariant, itemVariant, labelColor, itemColor }) => {
  return listItems.map(({ label, item }) => {
    return (
      item && (
        <FlexLayout flexDirection="row" justifyContent="space-between">
          <Text variant={labelVariant} color={labelColor}>
            {label}
          </Text>
          <Text variant={itemVariant} color={itemColor}>
            {item}
          </Text>
        </FlexLayout>
      )
    );
  });
};

ListWithLabels.propTypes = {
  listItems: PT.arrayOf({ label: PT.string, item: PT.string }),
  labelVariant: PT.string,
  itemVariant: PT.string,
  labelColor: PT.string,
  itemColor: PT.string,
};

export default ListWithLabels;
