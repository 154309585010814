import { theme } from '~/ui';

const { radii, space, colors, text } = theme;

export default {
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    background: 'transparent',
    border: '0 !important',
    height: '40px',
    outline: 'none',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
    },
  }),
  option: (styles) => ({
    ...styles,
    color: 'white',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: radii['m'],
    margin: `${space[1]} 0`,
    backgroundColor: '#202225',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  option: (_styles, state) => ({
    backgroundColor: state.isFocused ? colors['transparent'] : colors['body'],
    padding: space[4],
    color: colors['white'],
    ...text['label-m'],
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors['white'],
    ...text['label-m'],
    position: 'relative',
    top: '0',
    WebkitTransform: 'none',
    MozTransform: 'none',
    MsTransform: 'none',
    Otransform: 'none',
    transform: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 16px',
  }),
  input: (styles) => ({
    ...styles,
    color: colors['white'],
  }),
  multiValue: (styles) => ({
    ...styles,
    margin: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    padding: '0',
    paddingLeft: '0',
    ...text['label-s'],
    color: colors['text'],
    textTransform: 'uppercase',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    display: 'none',
  }),
};
