import React from 'react';
import PT from 'prop-types';

import { withDropdown } from '~/hocs';
import { useAuth } from '~/hooks';
import avatar from '~/ui/assets/images/avatar.png';
import { Icon, Box, FlexLayout, Text } from '~/ui';

const DropdownMenu = ({ clicked }) => {
  const { user } = useAuth();

  return (
    <FlexLayout alignItems="center" space={4} sx={{ cursor: 'pointer' }}>
      <Box as="img" src={avatar} borderRadius="round" sx={{ width: 'icon-xl', height: 'icon-xl' }} />
      <Text variant="label-semibold-m" upperCase>
        {user.firstName} {user.lastName}
      </Text>
      <Icon
        icon="chevronDown"
        sx={{ transform: `rotate(${clicked ? '-180' : '0'}deg)`, transition: 'rotate .4s ease' }}
      />
    </FlexLayout>
  );
};

DropdownMenu.propTypes = {
  clicked: PT.bool,
};

export default withDropdown(DropdownMenu);
