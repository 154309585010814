import PT from 'prop-types';
import React from 'react';
import { Input as ThemeInput } from 'theme-ui';

import FlexLayout from '../FlexLayout';
import { withFormController } from '~/hocs';
import Icon from '../Icon';
import { theme } from '~/ui';
import colors from '~/ui/theme/colors';

const sizesMap = {
  normal: {
    px: 4,
    py: 2,
  },
  search: {
    px: 3,
    py: 2,
  },
  small: {
    px: 3,
    py: 1,
  },
};

const widths = {
  f: '100%',
  search: '366px',
  radius: '60px',
};

const variantStyles = {
  primary: {
    bg: 'background',
  },
  transparent: {
    bg: 'rgba(255, 255, 255, 0.1)',
  },
};

const inputTypes = ['smallFont', 'mediumFont', 'largeFont', 'xSmallFont'];

const Input = React.forwardRef(
  (
    {
      placeholder = '',
      containerWidth = 'f',
      size = 'small',
      variant = 'primary',
      inputVariant = 'smallFont',
      type,
      icon = undefined,
      color = 'white',
      placeholderColor = 'white',
      displayNone = false,
      defaultValue = '',
      sx = {},
      ...props
    },
    ref,
  ) => {
    return (
      <FlexLayout
        sx={{
          ...sizesMap[size],
          ...variantStyles[variant],
          width: widths[containerWidth],
          borderRadius: 'm',
          display: displayNone == true ? 'none' : 'flex',
        }}
        width="full-div"
        space={3}
        alignItems="center"
      >
        {icon ? <Icon icon={icon} color={icon === 'lock' ? 'white' : 'body'} /> : null}
        <ThemeInput
          ref={ref}
          sx={{
            '&::-webkit-input-placeholder': {
              color: placeholderColor,
            },
            '&::-moz-placeholder': {
              color: placeholderColor,
            },
            '&::-ms-placeholder': {
              color: placeholderColor,
            },
            '&::placeholder': {
              color: placeholderColor,
            },
          }}
          variant={inputVariant}
          placeholder={placeholder}
          color={color}
          type={type}
          defaultValue={defaultValue}
          {...props}
        />
      </FlexLayout>
    );
  },
);

Input.propTypes = {
  size: PT.oneOf(Object.keys(sizesMap)),
  variant: PT.oneOf(Object.keys(variantStyles)),
  placeholder: PT.string,
  type: PT.string,
  containerWidth: PT.oneOf(Object.keys(widths)),
  color: PT.oneOf(Object.keys(colors)),
  placeholderColor: PT.oneOf(Object.keys(colors)),
  icon: PT.string,
  inputVariant: PT.oneOf(inputTypes),
  defaultValue: PT.string,
  sx: PT.object,
  displayNone: PT.bool,
};

Input.displayName = 'Input';

export default withFormController(Input);
