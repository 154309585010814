import PT from 'prop-types';
import React from 'react';

import { Button as ThemeButton } from 'theme-ui';
import { Text, theme } from '~/ui';
import colors from '~/ui/theme/colors';
import Icon from '../Icon';

const sizesMap = {
  normal: {
    py: 2,
    height: '40px',
  },
  small: {
    py: 1,
    height: '32px',
  },
};

const variantStyles = {
  primary: {
    bg: 'grey-white',
  },
  secondary: {
    bg: 'background',
  },
  third: {
    bg: 'yellow',
  },
  grey: {
    bg: 'grey',
  },
  danger: {
    bg: 'danger',
  },
};

const widths = {
  medium: '156px',
  fitContent: 'fit-content',
  fullWidth: '100%',
};

function Button({
  disabled,
  size = 'small',
  sx,
  text,
  ml,
  type = 'button',
  textVariant = 'label-s',
  textColor = 'body',
  variant = 'primary',
  icon,
  iconColor = 'white',
  left = false,
  width = 'fullWidth',
  onClick,
}) {
  return (
    <ThemeButton
      type={type}
      sx={{
        ...sizesMap[size],
        ...sx,
        ...variantStyles[variant],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: widths[width],
        borderRadius: 'm',
        pointerEvents: disabled ? 'none' : 'auto',
        cursor: 'pointer',
        ml,
      }}
      onClick={disabled ? null : onClick}
    >
      {icon && left ? <Icon icon={icon} color={iconColor} mr={2} size="mm" /> : null}
      <Text color={textColor} variant={textVariant} mr={icon && !left ? 2 : 0}>
        {text}
      </Text>
      {icon && !left ? <Icon icon={icon} size="mm" /> : null}
    </ThemeButton>
  );
}

Button.propTypes = {
  size: PT.oneOf(Object.keys(sizesMap)),
  ml: PT.any,
  text: PT.string,
  variant: PT.oneOf(Object.keys(variantStyles)),
  width: PT.oneOf(Object.keys(widths)),
  onClick: PT.func,
  disabled: PT.bool,
  sx: PT.object,
  type: PT.string,
  textColor: PT.string,
  textVariant: PT.string,
  icon: PT.string,
  iconColor: PT.oneOf(Object.keys(theme.colors)),
  left: PT.bool,
};

export default Button;
