import React from 'react';
import { Switch, Route } from 'react-router-dom';

import map from '~/ui/assets/images/map.png';
import { LoginSignUpForm, ForgotPasswordForm, ResetPasswordForm } from '~/components';
import { Box, FlexLayout, Text } from '~/ui';

function LandingPage() {
  return (
    <FlexLayout alignItems="center" justifyContent="center" flexGrow="1">
      <FlexLayout sx={{ width: 'form-wrapper-size', height: 'form-wrapper-height' }}>
        <FlexLayout sx={{ width: 'half-div' }} p={14} bg={'grey'} flexDirection="column">
          <Text variant="heading-l" mb={6} color="white">
            Welcome to GeoRisk
          </Text>
          <Switch>
            <Route exact path="/reset-password" render={(props) => <ResetPasswordForm {...props} />} />
            <Route exact path="/forgot" render={(props) => <ForgotPasswordForm {...props} />} />
            <Route path="/" render={(props) => <LoginSignUpForm {...props} />} />
          </Switch>
        </FlexLayout>
        <Box as="img" src={map} sx={{ width: 'half-div' }} />
      </FlexLayout>
    </FlexLayout>
  );
}

export default LandingPage;
