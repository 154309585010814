import React from 'react';

import { FlexLayout, Text } from '~/ui';

const formatDate = (value) => {
  let date = new Date(value);
  let month = date.toLocaleString('default', { month: 'short' });
  let day = date.getDate();
  let today = new Date();
  return (
    <FlexLayout flexDirection="column" space={1}>
      <Text variant="paragraph-s">{date === today ? 'Today' : month + ' ' + day}</Text>
      <Text variant="label-s" color="grey-400">
        At {date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
      </Text>
    </FlexLayout>
  );
};

export default formatDate;
