import React from 'react';
import PT from 'prop-types';

import { components } from 'react-select';

import FlexLayout from '../FlexLayout';
import Pill from '../Pill/Pill';

function DropdownIndicator(props) {
  return null;
}

const ValueContainer = ({ children, ...props }) => {
  const selectValue = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <FlexLayout alignItems={'center'} space={2}>
        {children}
      </FlexLayout>
    </components.ValueContainer>
  );
};

const MultiValueContainer = ({ children, ...props }) => {
  const {
    data: { label },
  } = props;

  return (
    <components.MultiValueContainer {...props}>
      <Pill value={label} />
    </components.MultiValueContainer>
  );
};

const optionPropType = PT.shape({
  label: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
});

MultiValueContainer.propTypes = {
  children: PT.array,
  data: optionPropType,
};

ValueContainer.propTypes = {
  children: PT.array,
  getValue: PT.func,
};

function IndicatorSeparator() {
  return null;
}

export default { DropdownIndicator, IndicatorSeparator, ValueContainer, MultiValueContainer };
