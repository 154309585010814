import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { InvestigationHeader, Investigation, InvestigationDetails } from '~/components';

import { PageLayout } from '~/ui';

import PT from 'prop-types';

function InvestigationPage({ data, meta }) {
  return (
    <Switch>
      <PageLayout color="grey" HeaderComponent={data.caseDetails && InvestigationHeader} data={data} meta={meta}>
        <Route
          exact
          path="/case/:id/investigation/details"
          render={(props) => data.caseDetails && <InvestigationDetails {...props} data={data} meta={meta} />}
        />
        <Route
          exact
          path="/case/:id/investigation"
          render={(props) => data.caseDetails && <Investigation {...props} data={data} meta={meta} />}
        />
      </PageLayout>
    </Switch>
  );
}

InvestigationPage.propTypes = {
  caseDetails: PT.object,
  setCaseDetails: PT.func,
  data: PT.any,
  meta: PT.any,
};
export default InvestigationPage;
