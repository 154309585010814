import React from 'react';
import PT from 'prop-types';
import { Textarea } from '@theme-ui/components';

import FlexLayout from '../FlexLayout';
import { withFormController } from '~/hocs';

const sizesMap = {
  normal: {
    px: 4,
    py: 3,
  },
};

const widths = {
  f: '100%',
};

const variantStyles = {
  primary: {
    bg: 'background',
  },
  transparent: {
    bg: 'rgba(255, 255, 255, 0.1)',
  },
};
function TextArea({
  placeholder = '',
  containerWidth = 'f',
  size = 'normal',
  variant = 'primary',
  rows = '7',
  name,
  ...props
}) {
  return (
    <FlexLayout
      sx={{ ...sizesMap[size], ...variantStyles[variant], width: widths[containerWidth], borderRadius: 'm', pr: 6 }}
      width="full-div"
      space={3}
      alignItems="center"
    >
      <Textarea placeholder={placeholder} rows={rows} name={name} {...props} />
    </FlexLayout>
  );
}

TextArea.propTypes = {
  size: PT.oneOf(Object.keys(sizesMap)),
  variant: PT.oneOf(Object.keys(variantStyles)),
  placeholder: PT.string,
  rows: PT.string,
  containerWidth: PT.oneOf(Object.keys(widths)),
  name: PT.string,
};

export default withFormController(TextArea);
