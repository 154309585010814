import { VirturisApi } from '~/apis';

function addDocument(caseId, file) {
  let formData = new FormData();
  formData.append('file', file);
  return VirturisApi.post(`cases/${caseId}/upload-csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function downloadFile(documentId) {
  return VirturisApi.get(`documents/${documentId}`, { responseType: 'blob' });
}

function deleteDocument(documentId) {
  return VirturisApi.delete(`/documents/${documentId}/delete`);
}

function attachCaseDocument(caseId, file) {
  let formData = new FormData();
  formData.append('file', file);
  return VirturisApi.post(`documents/${caseId}/attach-document`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

const documentsService = {
  addDocument,
  attachCaseDocument,
  downloadFile,
  deleteDocument,
};

export default documentsService;
