import React, { useEffect, useState, useCallback } from 'react';

import { Dashboard } from '~/components';
import { paginationConstants } from '~/constants';
import { casesService } from '~/services';
import { debounce } from 'lodash';

const THROTTLE_TIME = 175; //time in miliseconds

const DashboardContainer = (props) => {
  const [cases, setCases] = useState([]);
  const [search, setSearch] = useState('');
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [statusFilters, setStatusFilters] = useState(['Active', 'Pending']);
  const [paginationMeta, setPaginationMeta] = useState({
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: paginationConstants.PAGE_LIMIT,
    totalPages: 0,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(true);
  const [noSearchResults, setNoSearchResults] = useState(false);

  const searchFunction = (item) => {
    if (search !== '') {
      return (
        item.case.toLowerCase().includes(search.toLowerCase()) ||
        item.labels.map((label) => label.name.toLowerCase().includes(search.toLocaleLowerCase())).indexOf(true) > -1
      );
    } else {
      return true;
    }
  };

  const setSearchDelayed = useCallback(
    debounce((q) => {
      setSearch(q);
    }, THROTTLE_TIME),
    [],
  );

  useEffect(() => {
    casesService
      .getAll(paginationConstants.PAGE, paginationConstants.PAGE_LIMIT, statusFilters, search)
      .then(({ data }) => {
        if (data.items && data.length === 0) {
          setNoSearchResults(true);
        }
        setCases(data.items.map((item) => createCase(item)));
        setPaginationMeta(data.meta);

        setLoading(false);
      });
  }, [statusFilters, search]);

  const onPaginationChange = (page) => {
    casesService.getAll(page, paginationConstants.PAGE_LIMIT, statusFilters, search).then(({ data }) => {
      setCases(data.items.map((item) => createCase(item)));
      setPaginationMeta(data.meta);
    });
  };

  const addCase = (newCase) => {
    const createdCase = createCase(newCase);

    let tempCases = cases;
    if ((statusFilters.length === 0 || statusFilters.includes(createdCase.status)) && searchFunction(createdCase)) {
      //can be displayed
      if (cases.length === 0 || createdCase.id > cases[0].id) {
        //adding new case
        if (paginationMeta.currentPage === 1) {
          if (tempCases.length === paginationMeta.itemsPerPage) {
            tempCases.pop();
            setPaginationMeta({
              ...paginationMeta,
              itemCount: tempCases.length,
              totalItems: paginationMeta.totalItems + 1,
              totalPages: paginationMeta.totalPages + 1,
            });
          } else
            setPaginationMeta({
              ...paginationMeta,
              itemCount: tempCases.length,
              totalItems: paginationMeta.totalItems + 1,
            });
          setCases([createdCase, ...tempCases]);
        }
      } else {
        //editing case
        const index = tempCases.findIndex((item) => item.id === createdCase.id);
        if (index <= paginationMeta.itemsPerPage) {
          tempCases.splice(index, 1, createdCase);
          setCases([...tempCases]);
        }
      }
    } else {
      //is filtered out
      if (paginationMeta.currentPage === paginationMeta.totalPages) {
        //on last page -> can be deleted
        const index = cases.findIndex((item) => item.id === createdCase.id);
        tempCases.splice(index, 1);
        if (tempCases.length === 0) onPaginationChange(1);
        //page empty -> switch page
        else {
          setCases([...tempCases]);
          setPaginationMeta({
            ...paginationMeta,
            itemCount: tempCases.length,
            totalItems: paginationMeta.totalItems - 1,
          });
        }
      } else {
        onPaginationChange(paginationMeta.currentPage);
      }
    }
  };

  let metaData = {
    searchFunction,
    addCase,
    statusFilters,
    setStatusFilters,
    showStatusFilter,
    setShowStatusFilter,
    paginationMeta,
    onPaginationChange,
    loading,
    setSearchDelayed,
    noSearchResults,
    setNoSearchResults,
    setCases,
  };

  return <Dashboard data={cases} meta={metaData} {...props} />;
};

export const createCase = (item) => {
  return {
    id: item.caseId,
    case: item.name,
    uuid: item.id,
    status: item.status,
    description: item.description,
    labels: item.labels.map((label) => {
      return { name: label.name, id: label.id };
    }),
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  };
};

export default DashboardContainer;
