import React, { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import { Helmet } from 'react-helmet';

import { FlexLayout, Text, Button, Icon, Input, Table, Pagination } from '~/ui';
import { StatusFilters } from '~/components';
import { modalConstants, statuses } from '~/constants';
import { useModal, useOnClickOutside } from '~/hooks';
import { columns } from './colums';
import { useCaseHeader } from '~/hooks';

const Dashboard = ({
  data,
  meta: {
    searchFunction,
    addCase,
    statusFilters,
    setStatusFilters,
    showStatusFilter,
    setShowStatusFilter,
    paginationMeta,
    onPaginationChange,
    loading,
    setSearchDelayed,
    noSearchResults,
    setNoSearchResults,
    setCases,
  },
}) => {
  const { showModal } = useModal();
  const { configureCaseHeader } = useCaseHeader();
  const ref = useRef();
  useOnClickOutside(ref, () => setShowStatusFilter(false));

  const LinkRow = ({ item, children }) => {
    return <Link to={{ pathname: `case/${item.uuid}/investigation` }}>{children}</Link>;
  };

  LinkRow.propTypes = {
    item: caseShape,
    children: PT.node,
  };

  const RenderEmptyState = (props) => {
    const { title, reminder } = props;
    return (
      <FlexLayout space={4} alignItems="center" flexDirection="column">
        <Text variant="heading-semibold-m">{title}</Text>
        <Text variant="label-l" color="grey-400">
          {reminder}
        </Text>
      </FlexLayout>
    );
  };

  RenderEmptyState.propTypes = {
    title: PT.string,
    reminder: PT.string,
  };

  return (
    <FlexLayout flexShrink="0">
      <Helmet>
        <title>Dashboard | Virturis</title>
      </Helmet>
      <FlexLayout sx={{ width: 'page-width' }} py={10} flexDirection="column">
        <FlexLayout justifyContent="space-between" mb={12}>
          <Text variant="heading-semibold-l">Dashboard</Text>
          <Button
            text="New Case"
            textVariant="heading-s"
            width="fitContent"
            textColor="text-body"
            variant="third"
            icon="plusNoCircle"
            iconColor="body"
            left={true}
            size="normal"
            onClick={() => {
              showModal(modalConstants.MODAL_NEW_CASE, addCase);
            }}
          />
        </FlexLayout>
        <FlexLayout justifyContent="space-between" mb={data.length > 0 ? 8 : 30}>
          <Input
            icon="search"
            containerWidth="search"
            size="normal"
            variant="transparent"
            inputVariant="largeFont"
            placeholder="Search for anything..."
            onChange={(e) => {
              setSearchDelayed(e.target.value);
              setNoSearchResults(false);
            }}
          ></Input>
          <FlexLayout
            space={2}
            alignItems="center"
            onClick={() => {
              setShowStatusFilter(!showStatusFilter);
            }}
            mr={showStatusFilter ? -2 : null}
            sx={{ position: 'relative', cursor: 'pointer' }}
            ref={ref}
          >
            <Icon icon="union"></Icon>
            <Text variant="paragraph-s">Filter by Status</Text>
            <Icon
              icon="chevronDown"
              sx={{ transform: `rotate(${showStatusFilter ? '-180' : '0'}deg)`, transition: 'rotate .4s ease' }}
            ></Icon>
            {showStatusFilter ? (
              <StatusFilters
                setStatusFilters={(filters) => {
                  setStatusFilters(filters);
                  setNoSearchResults(false);
                }}
                statusFilters={statusFilters}
                setShowStatusFilter={setShowStatusFilter}
              />
            ) : null}
          </FlexLayout>
        </FlexLayout>

        {data.length > 0 ? (
          <Fragment>
            <Table
              data={data}
              columns={columns(data, addCase, paginationMeta, onPaginationChange)}
              WrapperComponent={LinkRow}
            />
            {paginationMeta.totalPages > 1 && (
              <Pagination totalRecords={paginationMeta.totalItems} onPageChanged={onPaginationChange} sx={{ mt: 4 }} />
            )}
          </Fragment>
        ) : !loading ? (
          <RenderEmptyState
            title={noSearchResults ? 'No cases found.' : 'You don’t have any active or pending cases.'}
            reminder={
              noSearchResults
                ? 'We couldn’t find any cases based on your filters.'
                : 'Start a new investigation by tapping on the “New Case” button above.'
            }
          />
        ) : null}
      </FlexLayout>
    </FlexLayout>
  );
};

const caseShape = PT.shape({
  id: PT.number,
  case: PT.string,
  uuid: PT.string,
  status: PT.string,
  description: PT.string,
  labels: PT.arrayOf(PT.object),
  createdAt: PT.string,
  updatedAt: PT.string,
});

Dashboard.propTypes = {
  data: PT.arrayOf(caseShape),
  meta: PT.shape({
    searchFunction: PT.func,
    addCase: PT.func,
    statusFilters: PT.array,
    setStatusFilters: PT.func,
    showStatusFilter: PT.bool,
    setShowStatusFilter: PT.func,
    paginationMeta: PT.any,
    onPaginationChange: PT.func,
    loading: PT.bool,
    setSearchDelayed: PT.func,
    noSearchResults: PT.bool,
    setNoSearchResults: PT.func,
    setCases: PT.func,
  }),
};

export default Dashboard;
