import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { sessionService } from '~/services';
import { Button, FlexLayout, Text, Box, Input } from '~/ui';
import { useAuth } from '~/hooks';

function LoginForm() {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { setLoggedInUser } = useAuth();
  const { control, handleSubmit } = useForm({
    mode: 'all',
  });

  const history = useHistory();

  function onSubmit(body) {
    sessionService.login(body).then(({ data, error }) => {
      if (error) {
        setErrorMessage(data.message);
        setError(true);
      } else {
        setErrorMessage('');
        setError(false);
        let { authToken, firstName, lastName } = data;
        setLoggedInUser(JSON.stringify({ firstName: firstName, lastName: lastName }), authToken);
      }
    });
  }

  return (
    <FlexLayout flexDirection="column">
      <Text variant="paragraph-m" mb={4}>
        LOG IN
      </Text>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={8}>
        <FlexLayout mb={error ? 3 : 10} space={4} flexDirection="column">
          <Input
            control={control}
            icon={error ? 'envelopeDanger' : 'envelope'}
            type="text"
            color="white"
            name="email"
            placeholder="Email address"
          />
          <Input
            control={control}
            color="white"
            icon={error ? 'lockDanger' : 'lock'}
            mr={3}
            name="password"
            type="password"
            placeholder="Password"
          />
        </FlexLayout>
        {error ? (
          <Text variant="label-s" mb={3} color="grey-400">
            {errorMessage}
          </Text>
        ) : null}
        <FlexLayout width="full-div" alignItems="center" space={4}>
          <Button
            text="Log In"
            variant="primary"
            width="medium"
            type="submit"
            textVariant="label-s"
            icon="arrowRight"
          />
          <Button
            text="Forgot your password?"
            textVariant="label-s"
            width="medium"
            textColor="white"
            variant="secondary"
            onClick={() => history.push('/forgot')}
          />
        </FlexLayout>
      </Box>
    </FlexLayout>
  );
}

export default LoginForm;
