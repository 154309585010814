import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getItem = (key) => {
  return cookies.get(key);
};

const setItem = (key, value, options = {}) => {
  cookies.set(key, value, options);
};

const removeItem = (key, options = {}) => {
  cookies.remove(key, options);
};

const cookie = {
  getItem,
  setItem,
  removeItem,
};

export default cookie;
