import axios from 'axios';

import config from '~/config';
import { cookie } from '~/utils';

const instance = axios.create({
  baseURL: config.backend_url,
});

instance.interceptors.request.use((config) => {
  const accessToken = cookie.getItem('token');

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return { data: response.data };
  },
  (error) => {
    if (error.response.status === 401) {
      cookie.removeItem('token');
      localStorage.removeItem('user');
    }
    return { data: error.response.data, error: true };
  },
);

const VirturisApi = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
};

export default VirturisApi;
