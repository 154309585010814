export const formatInlineDateUser = (user, value) => {
  let date = new Date(value);
  let month = date.toLocaleString('default', { month: 'short' });
  let day = date.getDate();
  let today = new Date();
  return `Last edited by ${user.firstName} ${user.lastName} on ${
    date === today ? 'Today' : month + ' ' + day
  } at ${date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
};

export const formatInlineDate = (value) => {
  let date = new Date(value);
  let month = date.toLocaleString('default', { month: 'short' });
  let day = date.getDate();
  let year = date.getFullYear();
  return month + ' ' + day + ', ' + year;
};
