import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InvestigationPage } from '~/components';
import { modalConstants } from '~/constants';
import { useAuth, useModal } from '~/hooks';
import { addressesService, casesService, documentsService, transactionsService } from '~/services';
import sanctionsService from '~/services/sanctionsService';
import { drawAddress, drawSanctions, drawTransactionLine, updateRadius } from '~/utils';

function InvestigationContainer() {
  const { showModal } = useModal();
  const { id } = useParams();
  const [caseDetails, setCaseDetails] = useState();
  const transactionRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [showTransactions, setShowTransactions] = useState(true);
  const [showAddresses, setShowAddresses] = useState(true);
  const [map, setMap] = useState(undefined);
  const documentRef = useRef();
  const [editInProgress, setEditInProgress] = useState(false);
  const [renderedObjects, setRenderedObjects] = useState({});
  const { user } = useAuth();
  let history = useHistory();

  useEffect(() => {
    casesService.getCaseDetails(id).then(({ data, error }) => {
      if (!error) {
        setCaseDetails(data);
      }
    });
  }, [id]);

  // CASES
  const updateCase = (body) => {
    const documents = caseDetails.documents;
    casesService
      .updateCase(caseDetails.id, body)
      .then(({ data, error }) => {
        if (!error) setCaseDetails({ ...data, documents: documents });
      })
      .catch((err) => console.log(err));
  };

  const deleteCase = () => {
    casesService
      .deleteCase(caseDetails.id)
      .then(({ data, error }) => {
        if (!error) history.push('/dashboard');
      })
      .catch((err) => console.log(err));
  };

  // DOCUMENTS
  const setDocuments = (documents) => {
    setCaseDetails({ ...caseDetails, documents });
  };

  const onDocumentSubmit = () => {
    const file = documentRef.current.files[0];
    documentsService
      .attachCaseDocument(id, file)
      .then(({ data, error }) => {
        if (!error) {
          const newDocument = createDocument(data);
          setDocuments([...caseDetails.documents, newDocument]);
        }
      })
      .catch((err) => console.log(err));
  };

  // DOCUMENTS
  const attachDocument = (e) => {
    documentRef.current.click();
  };

  const createDocument = (item) => {
    return {
      id: item.id,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      mimeType: item.mimeType,
      name: item.name,
      size: item.size,
    };
  };

  // TRANSACTIONS
  const uploadTransactions = (e) => {
    transactionRef.current.click();
  };

  const deleteTransaction = (transaction) => {
    transactionsService.deleteTransaction(transaction.id).then(({ error }) => {
      if (!error) {
        if (transaction.plotted) {
          hideTransaction(map, transaction);
        }
        setCaseDetails((prev) => {
          let beneficiaryAppearance = 0;
          let originatorAppeareance = 0;

          prev.transactions.forEach((t) => {
            if (t.beneficiaryAddress.id === transaction.beneficiaryAddress.id) {
              beneficiaryAppearance++;
            }
            if (t.originatorAddress.id === transaction.originatorAddress.id) {
              originatorAppeareance++;
            }
          });
          return {
            ...prev,
            transactions: prev.transactions.filter((t) => t.id !== transaction.id),
            caseAddresses: prev.caseAddresses.filter((c) => {
              if (c.id === transaction.beneficiaryAddress.id && beneficiaryAppearance === 1) {
                return false;
              }
              if (c.id === transaction.originatorAddress.id && originatorAppeareance === 1) {
                return false;
              }
              return true;
            }),
          };
        });
      }
    });
  };

  const openCollapseTransactions = () => {
    setShowTransactions(!showTransactions);
  };

  const onTransactionSubmit = () => {
    setIsUploading(true);
    documentsService
      .addDocument(id, transactionRef.current.files[0])
      .then(({ data, error }) => {
        if (error) {
          setUploadError(data.message);
        } else {
          setUploadError('');
          setCaseDetails((prev) => {
            return { ...prev, transactions: [...data, ...caseDetails.transactions] };
          });
        }
        setIsUploading(false);
      })
      .catch((err) => {
        setIsUploading(false);
        setUploadError(err.message);
      });
  };

  const hideTransaction = (map, transaction) => {
    const { beneficiaryAddress, originatorAddress } = transaction;

    let updateError;
    if (!transaction.delete) {
      transactionsService.updateTransaction(transaction.id, { plotted: false }).then(({ data, error }) => {
        if (error) {
          updateError = true;
        }
      });
    }

    if (updateError) {
      return;
    }

    let tmp = { ...renderedObjects };
    delete tmp[transaction.id];

    let somethingRemoved = false;
    let removeId = removePlotted(beneficiaryAddress, tmp);
    if (removeId) {
      delete tmp[removeId];
      somethingRemoved = true;
    }

    removeId = removePlotted(originatorAddress, tmp);
    if (removeId) {
      delete tmp[removeId];
      somethingRemoved = true;
    }

    removeAddressLayers(renderedObjects[transaction.id]);
    setRenderedObjects({ ...tmp });

    if (!transaction.delete) {
      setCaseDetails((prev) => {
        const index = prev.transactions.findIndex((t) => t.id === transaction.id);
        prev.transactions[index].plotted = false;
        return { ...prev };
      });
    }

    if (somethingRemoved && (beneficiaryAddress.plotRadius > 0 || originatorAddress.plotRadius > 0)) {
      replotSanctions(tmp);
    }
  };

  const removeAddressLayers = (address) => {
    address.layers.remove(address.objectId);
  };

  const showTransactionObjects = (map, transactions, replot = false) => {
    const tmp = { ...renderedObjects };
    transactions.forEach((transaction) => {
      if (transaction.plotted) {
        showTransaction(map, transaction, tmp, replot);
      }
    });
    setRenderedObjects(tmp);
    return tmp;
  };

  const showAddressObjects = (map, addresses, tmpRenderedObjects, replot = false) => {
    let objects = tmpRenderedObjects;
    addresses.forEach((address) => {
      if (address.plotted) {
        const tmp = showAddress(map, address, tmpRenderedObjects, replot);
        objects = tmp;
      }
    });
    return objects;
  };

  const showTransaction = (map, transaction, tmp = renderedObjects, replot) => {
    const { beneficiaryAddress, originatorAddress } = transaction;

    let transactionLayers = drawTransactionLine(map, transaction);
    let originatorAddressLayers, beneficiaryAddressLayers;

    if (!tmp[beneficiaryAddress.id]) {
      beneficiaryAddressLayers = drawAddress(map, beneficiaryAddress);
      tmp[beneficiaryAddress.id] = {
        ...makeAddressObject(beneficiaryAddress, beneficiaryAddressLayers),
        timesPlotted: 1,
      };
    } else {
      tmp[beneficiaryAddress.id].timesPlotted++;
    }

    if (!tmp[originatorAddress.id]) {
      originatorAddressLayers = drawAddress(map, originatorAddress);
      tmp[originatorAddress.id] = {
        ...makeAddressObject(originatorAddress, originatorAddressLayers),
        timesPlotted: 1,
      };
    } else {
      tmp[originatorAddress.id].timesPlotted++;
    }

    tmp[transaction.id] = {
      objectId: transaction.id,
      objectType: 'transaction',
      transaction,
      lineId: `transaction-${transaction.id}`,
      layers: transactionLayers,
      addresses: [originatorAddress.id, beneficiaryAddress.id],
    };

    setCaseDetails((prev) => {
      const index = prev.transactions.findIndex((t) => t.id === transaction.id);
      prev.transactions.splice(index, 1, transaction);
      return prev;
    });

    if (
      replot &&
      ((beneficiaryAddressLayers && beneficiaryAddress.plotRadius > 0) ||
        (originatorAddressLayers && originatorAddress.plotRadius > 0))
    ) {
      replotSanctions(tmp);
    }
  };

  // ADDRESSES
  const showAddress = (map, address, tmp = renderedObjects, replot = true) => {
    if (!tmp[address.id]) {
      return addPlotted(address, map, drawAddress(map, address), tmp, replot);
    } else {
      tmp[address.id].timesPlotted++;
    }
    return tmp;
  };

  const addAddress = (data) => {
    setCaseDetails((prev) => {
      return { ...prev, caseAddresses: [...prev.caseAddresses, data] };
    });
  };

  const openCollapseAddresses = () => {
    setShowAddresses(!showAddresses);
  };

  const hideAddress = (map, address) => {
    const removeId = removePlotted(address);
    if (removeId) {
      const tmp = { ...renderedObjects };
      delete tmp[address.id];
      if (address.plotRadius > 0) {
        replotSanctions(tmp);
      }
      setRenderedObjects(tmp);
    }
    setCaseDetails((tmp) => {
      const index = tmp.caseAddresses.findIndex((a) => a.id === address.id);
      tmp.caseAddresses.splice(index, 1, address);
      return { ...tmp };
    });
  };

  const updateAddressRadius = (map, address) => {
    addressesService
      .updateAddress(address.id, { plotRadius: Number(address.plotRadius), name: address.name })
      .then(({ data, error }) => {
        if (!error) {
          setCaseDetails((prev) => {
            const index = prev.caseAddresses.findIndex((a) => a.id === data.id);
            prev.caseAddresses[index] = data;
            return { ...prev };
          });
          setRenderedObjects((prev) => {
            prev[data.id].plotRadius = data.plotRadius;
            updateRadius(map, data);
            replotSanctions(prev, map);
            return prev;
          });
        }
      });
  };

  const addPlotted = (toAdd, map, layers, tmp = renderedObjects, replot) => {
    if (!toAdd.plotted) {
      addressesService.updateAddress(toAdd.id, { plotted: true, name: toAdd.name }).then(({ data, error }) => {
        if (!error) {
          setRenderedObjects((prev) => {
            prev[data.id] = {
              ...makeAddressObject(data, layers),
              timesPlotted: 1,
            };
            return { ...prev };
          });

          setCaseDetails((tmp) => {
            const index = tmp.caseAddresses.findIndex((address) => address.id === data.id);
            tmp.caseAddresses[index] = data;
            return { ...tmp };
          });
          if (data.plotRadius > 0) {
            const tempRendered = { ...tmp };
            tempRendered[data.id] = {
              ...makeAddressObject(data, layers),
              timesPlotted: 1,
            };
            if (replot) {
              replotSanctions(tempRendered);
            }
          }
        }
      });
    } else {
      setRenderedObjects((prev) => {
        prev[toAdd.id] = {
          ...makeAddressObject(toAdd, layers),
          timesPlotted: 1,
        };
        return { ...prev };
      });
      if (toAdd.plotRadius > 0) {
        const tempRendered = { ...tmp };
        tempRendered[toAdd.id] = {
          ...makeAddressObject(toAdd, layers),
          timesPlotted: 1,
        };
        if (replot) {
          replotSanctions(tempRendered);
        }
      }
      tmp[toAdd.id] = {
        ...makeAddressObject(toAdd, layers),
        timesPlotted: 1,
      };
      return tmp;
    }
  };

  const removePlotted = (toRemove, tmp = renderedObjects) => {
    if (tmp[toRemove.id].timesPlotted === 1) {
      removeAddressLayers(tmp[toRemove.id]);
      return toRemove.id;
    } else {
      tmp[toRemove.id].timesPlotted--;
    }
  };

  const makeAddressObject = (address, layers) => {
    return { objectId: address.id, layers, objectType: 'address', address };
  };

  const showSanctionsModal = (metaData) => {
    showModal(modalConstants.MODAL_SANCTION_DETAILS, null, metaData);
  };

  const toggleSanctions = (map, tmpRenderedObjects = renderedObjects, updateRiskKeys = false, initialize = false) => {
    if (!caseDetails.plottedRiskKeys.sanctions || initialize) {
      const caseAddresses = Object.values(tmpRenderedObjects).map((o) => {
        if (o.objectType === 'address' && o.address.plotRadius > 0) {
          return o.address.id;
        }
      });

      let updateError = false;
      if (updateRiskKeys) {
        casesService
          .updateCase(caseDetails.id, { plottedRiskKeys: { ...caseDetails.plottedRiskKeys, sanctions: true } })
          .then(({ data, error }) => {
            if (!error) {
              setCaseDetails(data);
            } else {
              updateError = true;
            }
          });
      }
      if (!updateError) {
        sanctionsService.getSanctions({ caseAddresses }).then(({ data, error }) => {
          if (!error) {
            const { removeLayers, editSource } = drawSanctions(map, data, showSanctionsModal);

            setRenderedObjects((prev) => {
              prev['sanctions'] = { objectType: 'sanctions', removeLayers, editSource };
              return { ...prev };
            });
          }
        });
      }
    } else {
      casesService
        .updateCase(caseDetails.id, { plottedRiskKeys: { ...caseDetails.plottedRiskKeys, sanctions: false } })
        .then(({ data, error }) => {
          if (!error) {
            setCaseDetails(data);

            const { removeLayers } = renderedObjects['sanctions'];
            setRenderedObjects((prev) => {
              delete prev['sanctions'];
              return { ...prev };
            });
            removeLayers();
          }
        });
    }
  };

  const replotSanctions = (temp = renderedObjects, map) => {
    if (caseDetails.plottedRiskKeys.sanctions || (map && map.getSource('sanctions'))) {
      const caseAddresses = Object.values(temp).map((o) => {
        if (o.objectType === 'address' && o.address.plotRadius > 0) {
          return o.address.id;
        }
      });
      sanctionsService.getSanctions({ caseAddresses }).then(({ data, error }) => {
        if (!error) {
          const { editSource } = temp['sanctions'];
          editSource(data);
        }
      });
    }
  };

  let data = {
    transactions: caseDetails ? caseDetails.transactions : [],
    caseDetails,
    user,
    addresses: caseDetails
      ? caseDetails.caseAddresses.filter((a) => {
          return !caseDetails.transactions.some(
            (t) => t.beneficiaryAddress.id === a.id || t.originatorAddress.id === a.id,
          );
        })
      : [],
  };

  let metaData = {
    transactionRef,
    documentRef,
    showTransactions,
    showAddresses,
    isUploading,
    uploadError,
    editInProgress,
    user,
    renderedObjects,
    map,
    onTransactionSubmit,
    attachDocument,
    uploadTransactions,
    openCollapseTransactions,
    openCollapseAddresses,
    setCaseDetails,
    onDocumentSubmit,
    setDocuments,
    setEditInProgress,
    updateCase,
    deleteCase,
    deleteTransaction,
    hideTransaction,
    showTransaction,
    hideAddress,
    showAddress,
    setMap,
    updateAddressRadius,
    setRenderedObjects,
    showTransactionObjects,
    showAddressObjects,
    addAddress,
    toggleSanctions,
  };

  return caseDetails ? <InvestigationPage data={data} meta={metaData} /> : null;
}

export default InvestigationContainer;
