import { VirturisApi } from '~/apis';

function getAll(page = paginationConstants.PAGE, limit = paginationConstants.PAGE_LIMIT, status = [], q) {
  let statusFilter = '';
  status.forEach((s) => (statusFilter += `&status[]=${s}`));
  return VirturisApi.get(`cases?page=${page}&limit=${limit}${statusFilter}&q=${q}`);
}

function getCaseDetails(id) {
  return VirturisApi.get(`cases/${id}`);
}

function createNewCase(body) {
  return VirturisApi.post(`cases/create`, body);
}

function updateCase(id, body) {
  return VirturisApi.patch(`cases/${id}/update`, body);
}
function getTransactions(id) {
  return VirturisApi.get(`/cases/${id}/transactions`);
}

function deleteCase(id) {
  return VirturisApi.delete(`/cases/${id}/delete`);
}

const casesService = {
  getAll,
  createNewCase,
  getCaseDetails,
  updateCase,
  getTransactions,
  deleteCase,
};

export default casesService;
