import React from 'react';

import { Text, FlexLayout, Button, Icon } from '~/ui';
import { formatDate } from '~/utils';
import { documentsService } from '~/services';
import { saveAs } from 'file-saver';

function renderHeader(title) {
  return (
    <Text variant="label-m" color="grey-300">
      {title}
    </Text>
  );
}

const DECIMAL_ROUND = 2;
const BYTE_TO_KILOBYTE = 1000;

const handleDownload = (event, item) => {
  event.preventDefault();

  documentsService
    .downloadFile(item.id)
    .then((res) => {
      const file = new Blob([res.data], { type: item.mimeType });

      saveAs(file, item.name);
    })
    .catch((err) => console.log(err));
};

export const columns = (documents, setDocuments) => {
  const handleDelete = (event, itemId) => {
    event.preventDefault();
    documentsService
      .deleteDocument(itemId)
      .then((res) => {
        if (!res.error) {
          setDocuments(documents.filter((i) => i.id !== itemId));
        }
      })
      .catch((err) => console.log(err));
  };

  return [
    {
      label: function header() {
        return renderHeader('DOCUMENT');
      },
      accessor: 'name',
      width: 400,
      render: function cell(item) {
        return (
          <FlexLayout space={2} alignItems="center">
            <Icon icon="document" color="white" size="l" />
            <Text variant="heading-semibold-s">{item.name}</Text>
          </FlexLayout>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('TYPE');
      },
      accessor: 'type',
      render: function cell(item) {
        return (
          <FlexLayout flexDirection="column">
            <Text variant="paragraph-s" sx={{ textTransform: 'uppercase' }}>
              {item.mimeType.substring(item.mimeType.indexOf('/') + 1)}
            </Text>
          </FlexLayout>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('SIZE');
      },
      accessor: 'labels',
      render: function cell(item) {
        return (
          <FlexLayout flexDirection="column">
            <Text variant="paragraph-s">{(item.size / BYTE_TO_KILOBYTE).toFixed(DECIMAL_ROUND)} kB</Text>
          </FlexLayout>
        );
      },
    },
    {
      label: function header() {
        return renderHeader('UPLOADED DATE');
      },
      accessor: 'uploadedAt',
      width: 120,
      render: function cell(item) {
        return formatDate(item.createdAt);
      },
    },
    {
      label: function header() {
        return renderHeader('');
      },
      accessor: 'delete',
      render: function cell(item) {
        return (
          <FlexLayout space={4} alignItems="center">
            <Button
              text="Download"
              variant="primary"
              width="fitContent"
              textVariant="label-m"
              onClick={(e) => handleDownload(e, item)}
            />
            <Button
              text="Delete"
              variant="primary"
              width="fitContent"
              textVariant="label-m"
              onClick={(e) => handleDelete(e, item.id)}
            />
          </FlexLayout>
        );
      },
    },
  ];
};
