import React, { createContext, useContext, useState } from 'react';
import PT from 'prop-types';

const CaseHeaderContext = createContext({});

/**
 * Custom hook to set and change the title of the header.* @param {Node} children children nodes to be rendered
 **/
const UseCaseHeaderProvider = (props) => {
  const { children } = props;

  const [title, setTitle] = useState(localStorage.getItem('title'));
  const [headerCase, setHeaderCase] = useState(JSON.parse(localStorage.getItem('headerCase')));

  const configureCaseHeader = (title, sterilizedCase) => {
    localStorage.setItem('title', title);
    localStorage.setItem('headerCase', JSON.stringify(sterilizedCase));
    setTitle(title);
    setHeaderCase(sterilizedCase);
  };

  return (
    <CaseHeaderContext.Provider
      value={{
        title,
        headerCase,
        configureCaseHeader,
      }}
    >
      {children}
    </CaseHeaderContext.Provider>
  );
};

const useCaseHeader = () => useContext(CaseHeaderContext);

UseCaseHeaderProvider.propTypes = {
  children: PT.node,
};

export default useCaseHeader;

export { UseCaseHeaderProvider };
