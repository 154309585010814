import React from 'react';
import { FlexLayout, Text } from '~/ui';
import PT from 'prop-types';
import { formatInlineDate, formatMoney } from '~/utils';

function TransactionInformationTooltip({ transaction, sx }) {
  return (
    <FlexLayout
      flexDirection="column"
      p={6}
      variant="primary"
      sx={{
        height: '256px',
        zIndex: 2,
        backgroundColor: 'body',
        minWidth: '480px',
        border: 'polar-border',
        borderRadius: 'm',
        ...sx,
      }}
      justifyContent="space-between"
      flexFlow="row nowrap"
    >
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Transaction Date
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {formatInlineDate(transaction.transactionDate)}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Transaction Type
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.transactionType}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Type
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.type}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Currency
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.currency}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Amount
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {formatMoney(transaction.transactionValue, transaction.currency)}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Originator
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.originatorName}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Originator Address
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.originatorAddress.name}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Beneficiary
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.beneficiaryName}
        </Text>
      </FlexLayout>
      <FlexLayout justifyContent="space-between">
        <Text variant="label-m" color="grey-400" sx={{ width: '150px' }}>
          Beneficiary Address
        </Text>
        <Text variant="label-m" color="white" sx={{ textAlign: 'right' }}>
          {transaction.beneficiaryAddress.name}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
}

TransactionInformationTooltip.propTypes = {
  transaction: PT.object,
  sx: PT.object,
};
export default TransactionInformationTooltip;
