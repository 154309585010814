const statuses = [
  { label: 'Pending', value: 0, color: 'warning', enum: 'Pending' },
  { label: 'Active', value: 1, color: 'safe', enum: 'Active' },
  { label: 'Closed (In Line with Expectation)', value: 2, color: 'grey-400', enum: 'ClosedInLineWithExpectation' },
  {
    label: 'Closed (Client Explained the Activity)',
    value: 3,
    color: 'grey-400',
    enum: 'ClosedClientExplainedActivity',
  },
  { label: 'Closed (No Red Flags)', value: 4, color: 'grey-400', enum: 'ClosedNoRedFlags' },
  { label: 'Closed (Client Exited)', value: 5, color: 'grey-400', enum: 'ClosedClientExited' },
  { label: 'Closed (Disclosure Filed)', value: 6, color: 'danger', enum: 'ClosedDisclosureFailed' },
];

export default statuses;
