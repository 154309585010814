import React, { useState } from 'react';

import { statuses, modalConstants } from '~/constants';
import { useModal } from '~/hooks';

import { EditDeletePopup } from '~/components';

import PT from 'prop-types';

import { FlexLayout, Icon, Link, Text, Select, RichTextEditor } from '~/ui';
import { useHistory } from 'react-router-dom';
import { Rnd } from 'react-rnd';
import NewWindow from 'react-new-window';

function InvestigationHeader({ data: { caseDetails }, meta: { updateCase, deleteCase } }) {
  const { showModal } = useModal();
  const history = useHistory();
  const [showSummaryEditor, setShowSummaryEditor] = useState(false);
  const [showSummaryPopout, setShowSummaryPopout] = useState(false);

  return (
    <FlexLayout alignItems="center" justifyContent="space-between" px={6} sx={{ borderBottom: 'header' }}>
      {showSummaryEditor && (
        <Rnd
          default={{
            x: localStorage.getItem('summaryEditorX') ? localStorage.getItem('summaryEditorX') : 0,
            y: localStorage.getItem('summaryEditorY') ? localStorage.getItem('summaryEditorY') : 0,
            width: 460,
            height: 400,
          }}
          onDragStop={(e, data) => {
            localStorage.setItem('summaryEditorX', data.x);
            localStorage.setItem('summaryEditorY', data.y);
          }}
          dragHandleClassName="dragHeader"
          style={{
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FlexLayout
            className="dragHeader"
            sx={{ cursor: 'move', borderTopLeftRadius: 'm', borderTopRightRadius: 'm' }}
            height={4}
            p={3}
            flexDirection="row"
            bg="grey-200"
            justifyContent="space-between"
          >
            <FlexLayout flexDirection="row" justifyContent="center" space={2} hei>
              <Icon icon="dragIndicator" color="grey-500" size="icon-l" />
              <Text variant="label-semibold-m" color="grey-500" py={2}>
                Case Notes
              </Text>
            </FlexLayout>
            <FlexLayout flexDirection="row" space={2} justifyContent="center">
              <Icon
                icon="expand"
                color="grey-500"
                size="icon-l"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowSummaryPopout(true);
                  setShowSummaryEditor(false);
                }}
              />
              <Icon
                icon="close"
                color="grey-500"
                size="icon-l"
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowSummaryEditor(false)}
              />
            </FlexLayout>
          </FlexLayout>

          <RichTextEditor previousEditorState={caseDetails.summary} onChange={updateCase} borderBottom={true} />
        </Rnd>
      )}
      {showSummaryPopout && (
        <NewWindow
          copyStyles={true}
          title={caseDetails.name + ' | Case Notes'}
          onBlock={() => alert('Please allow Virturis to open new browser tabs.')}
          onUnload={() => setShowSummaryPopout(false)}
        >
          <RichTextEditor previousEditorState={caseDetails.summary} onChange={updateCase} />
        </NewWindow>
      )}
      <FlexLayout space={10} alignItems="center">
        <FlexLayout space={2} alignItems="center">
          <Link path="/dashboard">
            <Icon icon="arrowLeft" size="l" color="yellow" />
          </Link>
          <Text variant="heading-semibold-m">{caseDetails.name}</Text>
        </FlexLayout>
        <Link title="Investigation" path={`/case/${caseDetails.id}/investigation`} icon="geo" />
        <Link title="Details" path={`/case/${caseDetails.id}/investigation/details`} icon="info" />
      </FlexLayout>
      <FlexLayout space={6} alignItems="center" justifyContent="space-between" sx={{ position: 'relative' }} mr={-10}>
        <FlexLayout
          onClick={() => setShowSummaryEditor((prev) => !prev)}
          sx={{ cursor: 'pointer', width: '180px' }}
          space={2}
        >
          <Icon icon="pencil" size="icon-l" color={showSummaryEditor ? 'yellow' : 'white'} label="Case Notes" />
          <Text variant="label-m" color={showSummaryEditor ? 'yellow' : 'white'}>
            Case Notes
          </Text>
        </FlexLayout>
        <Select
          onChange={(status) => {
            updateCase({ status: status.enum });
          }}
          color="white"
          size="nopad"
          width="small"
          options={statuses}
          variant="nobg"
          valuePad="0"
          value={statuses.find((s) => s.enum === caseDetails.status)}
        />
        <EditDeletePopup
          iconSize="l"
          dotsColor="yellow"
          marginLeft="-90px"
          sx={{ top: 4, left: -37 }}
          options={[
            {
              icon: 'pencil',
              label: 'Edit',
              onClick: () => {
                showModal(modalConstants.MODAL_NEW_CASE, updateCase, {
                  name: caseDetails.name,
                  description: caseDetails.description,
                  labels: caseDetails.labels.map((el) => {
                    return { value: el.id, label: el.name };
                  }),
                  status: statuses.find((s) => s.enum === caseDetails.status),
                  id: caseDetails.id,
                });
              },
            },
            {
              icon: 'delete',
              label: 'Delete',
              onClick: () => {
                showModal(modalConstants.MODAL_DELETE_CASE, (id) => deleteCase(id), { id: caseDetails.id });
              },
            },
          ]}
        />
      </FlexLayout>
    </FlexLayout>
  );
}
InvestigationHeader.propTypes = {
  data: PT.shape({
    caseDetails: PT.any,
  }),
  meta: PT.shape({
    setCaseDetails: PT.func,
    updateCase: PT.func,
    deleteCase: PT.func,
  }),
};

export default InvestigationHeader;
