import React, { createContext, useContext, useState } from 'react';
import PT from 'prop-types';
import modalConstants, { modalTitles } from '~/constants/modalConstants';

const ModalContext = createContext({});

/**
 * Custom hook and context for hiding or showing modal.* @param {Node} children children nodes to be rendered
 **/
const UseModalProvider = (props) => {
  const { children } = props;

  const [modalType, setModalType] = useState('');
  const [width, setWidth] = useState('480px');
  const [isOpen, setIsOpen] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [title, setTitle] = useState('');
  const [modalCallback, setModalCallback] = useState();

  const showModal = (modalType, callback = null, metadata = {}) => {
    document.querySelector('body').classList.add('no-scroll');
    setModalCallback(() => (data) => callback(data));
    setModalType(modalType);
    setTitle(modalTitles[modalType]);
    setMetaData(metadata);
    setIsOpen(true);
    if ((modalType = modalConstants.MODAL_SANCTION_DETAILS)) {
      setWidth('608px');
    }
  };

  const hideModal = (callbackData = undefined) => {
    document.querySelector('body').classList.remove('no-scroll');
    setModalType('');
    setTitle('');
    setIsOpen(false);
    if (modalCallback && callbackData) {
      modalCallback(callbackData);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        width,
        modalType,
        isOpen,
        title,
        metaData,
        showModal,
        hideModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

UseModalProvider.propTypes = {
  children: PT.node,
};

export default useModal;

export { UseModalProvider };
