const fitAll = (map, renderedObjects) => {
  let north = -90,
    south = 90,
    east = -180,
    west = 180;

  let number = 0;

  const objects = Object.values(renderedObjects);

  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];
    if (object.objectType == 'address') {
      number++;
      const { lat, lng } = object.address.address;

      if (lat > north) north = Number(lat);
      if (lat < south) south = Number(lat);
      if (lng > east) east = Number(lng);
      if (lng < west) west = Number(lng);
    }
  }

  if (number === 0) return;
  if (number === 1) {
    map.flyTo({
      center: [(east + west) / 2, (north + south) / 2],
      essential: true,
      zoom: 13,
    });
    return;
  }
  map.fitBounds(
    [
      [west, south],
      [east, north],
    ],
    { padding: number !== 1 ? 100 : 300 },
  );
};

export default fitAll;
