import React, { Fragment, useEffect, useState } from 'react';
import PT from 'prop-types';

import { modalConstants } from '~/constants';
import { Card, FlexLayout, Text, Icon, Button, Input, Box } from '~/ui';
import { keyframes } from '@emotion/react';
import { useModal } from '~/hooks';
import Transactions from './Transactions';
import Addresses from './Addresses';
import RiskKeys from './RiskKeys';
import { meta } from '@turf/turf';

const LeftSidebar = ({
  data: { transactions, caseDetails, addresses },
  meta: {
    addAddress,
    transactionRef,
    onTransactionSubmit,
    uploadTransactions,
    openCollapseTransactions,
    openCollapseAddresses,
    isUploading,
    uploadError,
    showTransactions,
    deleteTransaction,
    hideTransaction,
    showAddresses,
    renderedObjects,
    setRenderedObjects,
    showAddress,
    hideAddress,
    setCaseDetails,
    showTransactionObjects,
    setAddresses,
    toggleSanctions,
  },
  map,
}) => {
  const [getStarted, setGetStarted] = useState(localStorage.getItem('getStarted'));
  const { showModal } = useModal();

  const loadingSpinner = (
    <Icon
      icon="loading"
      sx={{
        animationName: keyframes`
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        `,
        animationDuration: '1s',
        animationFillMode: 'backwards',
        animationIterationCount: 'infinite',
      }}
    ></Icon>
  );

  return (
    <Fragment>
      <FlexLayout
        flexDirection="column"
        space={6}
        sx={{
          paddingRight: '600px',
          marginRight: '-600px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
          '&::-ms-overflow-style': { display: 'none' },
        }}
      >
        {!getStarted ? (
          <Card
            title="GETTING STARTED"
            icon="close"
            iconColor="white"
            margin="none"
            onClick={() => {
              setGetStarted(true);
              localStorage.setItem('getStarted', true);
            }}
          >
            <FlexLayout bg="grey" py={3} px={2} sx={{ borderRadius: 'm' }}>
              <Text variant="label-m">
                Start by uploading a database of recent transactions. Each transaction available will be in the list
                below from where you can plot and inspect party, counterparty and any other data available. The relevant
                parties of each transaction will appear on the map and selected Risk Key factors will be highlighted
                inside their radius.{' '}
              </Text>
            </FlexLayout>
          </Card>
        ) : null}
        <FlexLayout flexDirection="column" alignItems="center">
          {isUploading ? (
            loadingSpinner
          ) : (
            <Box as="form" onChange={onTransactionSubmit} sx={{ width: '368px' }}>
              <Button
                text="Upload transactions"
                variant="grey"
                textColor="yellow"
                width="fullWidth"
                left={true}
                textVariant="label-s"
                icon="file"
                onClick={uploadTransactions}
              ></Button>
              <Input ref={transactionRef} type="file" name="file" displayNone={true} />
            </Box>
          )}
          {uploadError && (
            <Text variant="label-l" color="danger">
              {uploadError}
            </Text>
          )}
        </FlexLayout>
        <Card
          title="Transactions"
          icon="caretUp"
          iconSx={{ transform: `rotate(${!showTransactions ? '-180' : '0'}deg)`, transition: 'rotate .4s ease' }}
          height={50}
          width={50}
          onClick={openCollapseTransactions}
        >
          {showTransactions && (
            <Transactions
              transactions={transactions}
              hideTransaction={hideTransaction}
              deleteTransaction={deleteTransaction}
              showTransactionsObjects={showTransactionObjects}
              renderedObjects={renderedObjects}
              map={map}
            />
          )}
        </Card>
        <FlexLayout flexDirection="column" space={2}>
          <Card
            title="Address"
            icon="caretUp"
            onClick={openCollapseAddresses}
            iconSx={{ transform: `rotate(${!showAddresses ? '-180' : '0'}deg)`, transition: 'rotate .4s ease' }}
          >
            <Button
              text="Add New Address"
              variant="grey"
              textColor="yellow"
              width="fullWidth"
              left={true}
              sx={{
                mb: 2,
              }}
              onClick={() => {
                showModal(modalConstants.MODAL_NEW_ADDRESS, addAddress, { caseId: caseDetails.id });
              }}
              textVariant="label-s"
              icon="plus"
            />
            {showAddresses && (
              <Addresses
                data={{ addresses, renderedObjects, caseData: caseDetails }}
                meta={{ showAddress, hideAddress, setCaseDetails, setAddresses, setRenderedObjects }}
                map={map}
              />
            )}
          </Card>
        </FlexLayout>
        <Card title="Risk Key" icon="caretUp">
          <RiskKeys data={{ map, plottedRiskKeys: caseDetails.plottedRiskKeys }} meta={{ toggleSanctions }} />
        </Card>
      </FlexLayout>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  data: PT.shape({ transactions: PT.array, addresses: PT.array, caseDetails: PT.any }),
  meta: PT.shape({
    addAddress: PT.func,
    transactionRef: PT.any,
    onTransactionSubmit: PT.func,
    attachDocument: PT.func,
    isUploading: PT.bool,
    openCollapseTransactions: PT.func,
    openCollapseAddresses: PT.func,
    uploadTransactions: PT.func,
    uploadError: PT.string,
    showTransactions: PT.bool,
    showAddresses: PT.bool,
    deleteTransaction: PT.func,
    hideTransaction: PT.func,
    showTransaction: PT.func,
    hideAddress: PT.func,
    showAddress: PT.func,
    setCaseDetails: PT.func,
    showTransactionObjects: PT.func,
    addAddress: PT.func,
    setAddresses: PT.func,
    renderedObjects: PT.object,
    setRenderedObjects: PT.func,
    sanctions: PT.bool,
    toggleSanctions: PT.func,
  }),
  map: PT.any,
};

export default LeftSidebar;
