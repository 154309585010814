import PT from 'prop-types';
import React from 'react';
import { useFlexLayout, useTable } from 'react-table';

import { Box, FlexLayout, Text } from '~/ui';

const DEFAULT_CELL_WIDTH = 150;

function TableComponent({ columns, data, WrapperComponent }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useFlexLayout,
  );

  const renderRow = (row) => {
    return (
      <FlexLayout
        key={row.original.uuid}
        {...row.getRowProps()}
        sx={{ borderBottom: 'border-input' }}
        py={4}
        alignItems="center"
      >
        {row.cells.map((cell, index) => (
          <FlexLayout
            key={index}
            {...cell.getCellProps()}
            alignItems="center"
            justifyContent={cell.column.alignToCenter ? 'center' : undefined}
            p={0}
            pl={0}
            sx={{ height: 'row-height' }}
          >
            {cell.render('Cell')}
          </FlexLayout>
        ))}
      </FlexLayout>
    );
  };

  return (
    <Box {...getTableProps()}>
      {headerGroups.map((headerGroup, index1) => (
        <FlexLayout key={index1} {...headerGroup.getHeaderGroupProps()} sx={{ borderBottom: 'border-input' }} py={4}>
          {headerGroup.headers.map((column, index2) => (
            <FlexLayout
              key={index2}
              {...column.getHeaderProps()}
              alignItems="center"
              justifyContent={column.alignToCenter ? 'center' : undefined}
              p={4}
              pl={0}
            >
              <Text>{column.render('Header')}</Text>
            </FlexLayout>
          ))}
        </FlexLayout>
      ))}
      <Box {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return WrapperComponent ? (
            <WrapperComponent key={row.original.uuid} item={row.original}>
              {renderRow(row)}
            </WrapperComponent>
          ) : (
            renderRow(row)
          );
        })}
      </Box>
    </Box>
  );
}

function Table({ columns, data, WrapperComponent = null }) {
  const mappedColumns = columns.map((column) => {
    const { label, accessor, width, render } = column;

    return {
      Header: label,
      accessor: accessor,
      Cell: ({ row, cell }) => (render ? render(row.original) : cell.value),
      width: width || DEFAULT_CELL_WIDTH,
    };
  });

  return <TableComponent columns={mappedColumns} WrapperComponent={WrapperComponent} data={data} />;
}

const columnPropType = PT.shape({
  alignToCenter: PT.bool,
  label: PT.func,
  value: PT.string,
  renderCustomCell: PT.func,
  width: PT.number,
});

TableComponent.propTypes = {
  columns: PT.arrayOf(columnPropType),
  data: PT.arrayOf(PT.object),
  WrapperComponent: PT.any,
};

Table.propTypes = {
  columns: PT.arrayOf(columnPropType),
  data: PT.arrayOf(PT.object),
  WrapperComponent: PT.any,
};

export default Table;
