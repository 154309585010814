import { VirturisApi } from '~/apis';

function login(body) {
  return VirturisApi.post('auth/login', body);
}

function forgot(body) {
  return VirturisApi.post('users/password/request', body);
}

function register(body) {
  return VirturisApi.post('users/create', body);
}

function reset(body) {
  return VirturisApi.post('users/password/confirm', body);
}

const sessionService = {
  login,
  register,
  forgot,
  reset,
};

export default sessionService;
