import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { FlexLayout } from '~/ui';
import { LandingPage, Modal } from '~/components';
import { DefaultLayout } from '~/layouts';
import { useAuth } from '~/hooks';
import { InvestigationContainer } from '~/containers';

function ContentWindow() {
  const { user, token } = useAuth();

  return (
    <Switch>
      {user && token ? (
        <FlexLayout flexDirection="column" sx={{ width: 'full-div' }}>
          <Modal />
          <Route path="/case/:id/investigation" render={(props) => <InvestigationContainer {...props} />} />
          <Route path="/results" render={(props) => <DefaultLayout {...props} />} />
          <Route path="/dashboard" render={(props) => <DefaultLayout {...props} />} />
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        </FlexLayout>
      ) : (
        <Route render={(props) => <LandingPage {...props} />} />
      )}
    </Switch>
  );
}

export default ContentWindow;
