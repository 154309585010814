const checkPassword = (password, setError) => {
  let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if (!password.match(regex)) {
    setError(true);
    return false;
  } else {
    return true;
  }
};

export default checkPassword;
