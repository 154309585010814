import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

function LoginSignUpForm() {
  return (
    <Fragment>
      <Helmet>
        <title>Log In or Sign Up | Virturis</title>
      </Helmet>
      <LoginForm />
      <SignupForm />
    </Fragment>
  );
}

export default LoginSignUpForm;
