import typography from './typography';

const defaultInputStyles = {
  width: '100%',
  border: 'none',
  background: 'transparent',
  padding: '0',
  margin: '0',
  outline: 'none',
  '&::placeholder': {
    color: 'inherit',
    opacity: 1,
  },
};

export default {
  xSmallFont: {
    height: '24px',
    ...typography.variants['label-s'],
    ...defaultInputStyles,
  },
  smallFont: {
    height: 'input-height',
    ...typography.variants['label-s'],
    ...defaultInputStyles,
  },
  mediumFont: {
    height: 'input-height',
    ...typography.variants['label-m'],
    ...defaultInputStyles,
  },
  largeFont: {
    height: 'input-height',
    ...typography.variants['label-l'],
    ...defaultInputStyles,
  },
  textarea: {
    height: 'textarea-height',
    ...typography.variants['label-m'],
    ...defaultInputStyles,
  },
  select: {
    width: '100%',
    background: 'transparent',
    ...typography.variants['label-m'],
  },
};
