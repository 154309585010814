import React from 'react';
import PT from 'prop-types';

import { useHover } from '~/hooks';

const withHover = (WrappedComponent, props) => {
  const [isHovered, eventHandlers] = useHover();

  return <WrappedComponent isHovered={isHovered} eventHandlers={eventHandlers} {...props} />;
};
withHover.propTypes = {
  WrappedComponent: PT.node,
};

export default withHover;
