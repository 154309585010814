import PT from 'prop-types';
import React, { useState } from 'react';
import { EditDeletePopup } from '~/components';
import { modalConstants } from '~/constants';
import { useModal } from '~/hooks';
import { transactionsService } from '~/services';
import { Checkbox, FlexLayout, Icon, Text } from '~/ui';
import { formatInlineDate, formatMoney } from '~/utils';
import TransactionInformationTooltip from './TransactionInformationTooltip';

const Transactions = ({
  transactions,
  deleteTransaction,
  hideTransaction,
  renderedObjects,
  showTransactionsObjects,
  map,
}) => {
  const [hoveredInfo, setHoveredInfo] = useState(undefined);
  const { showModal } = useModal();

  return transactions && transactions.length > 0 ? (
    <FlexLayout flexDirection="column" sx={{ borderRadius: 'm' }}>
      <FlexLayout
        bg="grey"
        p={2}
        space={6}
        sx={{ height: '24px', borderTopRightRadius: 'm', borderTopLeftRadius: 'm' }}
        alignItems="center"
      >
        <Text variant="label-xs" color="grey-400">
          Plot
        </Text>
        <Text variant="label-xs" color="grey-400" ml={4}>
          Transaction
        </Text>
      </FlexLayout>
      {transactions.map((transaction, index) => {
        return (
          <FlexLayout
            key={transaction.id}
            bg="grey"
            px={2}
            py={3}
            mt="1px"
            space={6}
            sx={{
              position: 'relative',
              width: '100%',
              borderBottomLeftRadius: index == transactions.length - 1 ? 'm' : null,
              borderBottomRightRadius: index == transactions.length - 1 ? 'm' : null,
            }}
            alignItems="center"
          >
            <Checkbox
              border={null}
              isActive={transaction.plotted}
              onChange={() => {
                if (!transaction.plotted) {
                  transactionsService.updateTransaction(transaction.id, { plotted: true }).then(({ data, error }) => {
                    if (!error) {
                      showTransactionsObjects(map, [data], true);
                    }
                  });
                } else {
                  hideTransaction(map, transaction);
                }
              }}
              height="icon-m"
              width="icon-m"
              sx={{ mr: '24px' }}
            />
            <FlexLayout flexDirection="column" justifyContent="center" sx={{ width: '240px' }}>
              <Text variant="label-m" color="white">
                {transaction.originatorName}
              </Text>
              <Text variant="label-m" color="white">
                → {transaction.beneficiaryName}
              </Text>
              <Text variant="label-s" color="grey-400">
                {formatInlineDate(transaction.transactionDate)} ·{' '}
                {formatMoney(transaction.transactionValue, transaction.currency)}
              </Text>
            </FlexLayout>
            <FlexLayout
              sx={{ height: '24px', width: '24px', cursor: 'pointer', position: 'relative' }}
              onMouseEnter={() => {
                setHoveredInfo(index);
              }}
              onMouseLeave={() => {
                setHoveredInfo(null);
              }}
              mr="20px"
            >
              <Icon icon="infoCircle" />
              {hoveredInfo === index && (
                <TransactionInformationTooltip
                  sx={{ top: 8, left: 8, position: 'absolute' }}
                  transaction={transaction}
                />
              )}
            </FlexLayout>
            <EditDeletePopup
              sx={{
                top: 1,
                left: 8,
                zIndex: 1,
              }}
              dotsColor="grey-500"
              iconSize="s"
              options={[
                {
                  icon: 'delete',
                  label: 'Delete',
                  onClick: () => {
                    showModal(modalConstants.MODAL_DELETE_TRANSACTION, deleteTransaction, {
                      ...transaction,
                      delete: true,
                    });
                  },
                },
              ]}
            />
          </FlexLayout>
        );
      })}
    </FlexLayout>
  ) : (
    <Text variant="label-s" textColor="red">
      No transactions available
    </Text>
  );
};

const TransactionShape = PT.shape({
  beneficiaryAddress: PT.any,
  beneficiaryGeo: PT.shape({
    data: PT.shape({
      geometry: PT.shape({
        lat: PT.number,
        lng: PT.number,
      }),
    }),
  }),
  beneficiaryName: PT.string,
  createdAt: PT.string,
  currency: PT.string,
  id: PT.string,
  originatorAddress: PT.any,
  originatorGeo: PT.shape({
    data: PT.shape({
      geometry: PT.shape({
        lat: PT.number,
        lng: PT.number,
      }),
    }),
  }),
  originatorName: PT.string,
  transactionType: PT.string,
  transactionValue: PT.string,
  type: PT.string,
  updatedAt: PT.string,
});

Transactions.propTypes = {
  transactions: PT.arrayOf(TransactionShape),
  showTransaction: PT.func,
  deleteTransaction: PT.func,
  hideTransaction: PT.func,
  renderedObjects: PT.object,
  showTransactionsObjects: PT.func,
  map: PT.object,
};

export default Transactions;
