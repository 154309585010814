import PT from 'prop-types';
import React from 'react';
import radii from '~/ui/theme/radii';
import { FlexLayout, Text, Icon } from '~/ui/components';
import typography from '~/ui/theme/typography';
import colors from '~/ui/theme/colors';
import { theme } from '~/ui';

const margins = {
  none: '0',
  s: '8px',
  m: '20px',
  l: '40px',
};

const paddings = {
  none: 0,
  s: 2,
  m: 4,
  ml: 6,
  l: 8,
};

const variantStyles = {
  primary: {
    bg: 'background',
  },
  transparent: {
    bg: 'transparent',
  },
  grey: {
    bg: 'grey',
  },
};

const Card = React.forwardRef(
  (
    {
      title,
      isTitleUppercase = true,
      icon,
      rightText,
      margin = 'm',
      padding = 'none',
      borderRadius = 'none',
      variant = 'transparent',
      titleVariant = 'label-semibold-m',
      rightTextVariant = 'label-semibold-m',
      iconColor = 'white',
      rightTextColor,
      children,
      onClick,
      sx = {},
      iconSx = {},
      ...rest
    },
    ref,
  ) => {
    return (
      <FlexLayout
        flexDirection="column"
        p={paddings[padding]}
        sx={{ ...sx, ...variantStyles[variant], borderRadius }}
        {...rest}
      >
        <FlexLayout mb={margins[margin]} justifyContent="space-between">
          <Text sx={{ textTransform: isTitleUppercase === 'true' ? 'uppercase' : null }} variant={titleVariant}>
            {title}
          </Text>
          <FlexLayout
            space={2}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              onClick();
            }}
          >
            <Icon icon={icon} color={iconColor} sx={iconSx}></Icon>
            {rightText ? (
              <Text variant={rightTextVariant} color={rightTextColor ? rightTextColor : null}>
                {rightText}
              </Text>
            ) : null}
          </FlexLayout>
        </FlexLayout>
        {children}
      </FlexLayout>
    );
  },
);

Card.propTypes = {
  title: PT.string,
  isTitleUppercase: PT.bool,
  icon: PT.string,
  rightText: PT.string,
  rightTextVariant: PT.oneOf(Object.keys(typography.variants)),
  rightTextColor: PT.oneOf(Object.keys(theme.colors)),
  iconColor: PT.oneOf(Object.keys(theme.colors)),
  margin: PT.oneOf(Object.keys(margins)),
  padding: PT.oneOf(Object.keys(paddings)),
  borderRadius: PT.oneOf(Object.keys(radii)),
  variant: PT.oneOf(Object.keys(variantStyles)),
  titleVariant: PT.oneOf(Object.keys(typography.variants)),
  children: PT.node,
  iconSx: PT.object,
  sx: PT.object,
  onClick: PT.func,
};

Card.displayName = 'Card';

export default Card;
