import borders from './borders';
import colors from './colors';
import forms from './forms';
import radii from './radii';
import sizes from './sizes';
import space from './space';
import typography from './typography';

export default {
  colors: {
    ...colors.alphas,
    ...colors.palette,
    background: colors.palette.body,
    primary: colors.palette.white,
    text: colors.palette.white,
  },
  forms: forms,
  fonts: typography.fonts,
  fontWeights: typography.fontWeights,
  radii,
  sizes,
  space,
  text: typography.variants,
  borders: borders,
};
