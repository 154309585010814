import { VirturisApi } from '~/apis';

function deleteTransaction(transactionId) {
  return VirturisApi.delete(`/transactions/${transactionId}/delete`);
}

function updateTransaction(transactionId, body) {
  return VirturisApi.patch(`/transactions/${transactionId}`, body);
}

const documentsService = {
  deleteTransaction,
  updateTransaction,
};

export default documentsService;
