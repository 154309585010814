import PT from 'prop-types';
import React, { Fragment } from 'react';

import FlexLayout from '../FlexLayout';

function PageLayout({ children, HeaderComponent, color, data, meta }) {
  return (
    <Fragment>
      {HeaderComponent ? <HeaderComponent data={data} meta={meta} /> : null}
      <FlexLayout justifyContent="center" flexGrow="1" bg={color}>
        {children}
      </FlexLayout>
    </Fragment>
  );
}

PageLayout.propTypes = {
  HeaderComponent: PT.elementType,
  children: PT.node,
  color: PT.string,
  data: PT.any,
  meta: PT.any,
};

export default PageLayout;
