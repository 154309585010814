import PT from 'prop-types';
import React from 'react';
import { Box as ThemeBox } from 'theme-ui';

const Box = React.forwardRef(({ sx, ...rest }, ref) => {
  return <ThemeBox ref={ref} sx={{ ...sx }} {...rest} />;
});

Box.propTypes = {
  sx: PT.object,
};

Box.displayName = 'Box';

export default Box;
