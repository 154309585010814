import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { Text, FlexLayout, Icon } from '~/ui';

import Address from './Address';

const PlottedAddresses = ({ data: { addresses }, meta: { updateAddressRadius, renderedObjects }, map }) => {
  const [last, setLast] = useState(null);

  useEffect(() => {
    const index = Object.values(renderedObjects)
      .slice()
      .reverse()
      .findIndex((o) => o.objectType === 'address');
    setLast(index >= 0 ? renderedObjects.length - 1 - index : null);
  }, [renderedObjects]);

  const checkRenderedObjects = () => {
    if (!renderedObjects) {
      return false;
    }
    if (
      Object.values(renderedObjects).length === 0 ||
      (Object.values(renderedObjects).length === 1 && renderedObjects['sanctions'])
    ) {
      return false;
    }
    return true;
  };

  return checkRenderedObjects() ? (
    <FlexLayout flexDirection="column" sx={{ width: '367px' }}>
      <FlexLayout
        bg="grey"
        p={2}
        space={6}
        sx={{ height: '24px', borderTopRightRadius: 'm', borderTopLeftRadius: 'm' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text variant="label-xs" color="grey-400">
          Address
        </Text>
        <Text variant="label-xs" color="grey-400" ml={4}>
          Radius (m)
        </Text>
      </FlexLayout>
      {Object.values(renderedObjects).map((object, index) => {
        if (object.objectType === 'address')
          return (
            <Address
              key={object.objectId}
              borderBottom={index === last}
              address={object.address}
              map={map}
              renderedObjects={renderedObjects}
              updateAddressRadius={updateAddressRadius}
            />
          );
      })}
    </FlexLayout>
  ) : (
    <FlexLayout p={2} sx={{ borderRadius: 'm' }} alignItems="center" space={2} flexDirection="column">
      <Icon icon="emptyGeoIntelligence" size="xxl" />
      <Text variant="label-m" color="grey-500" py={2} sx={{ width: '240px', textAlign: 'center' }}>
        Once you plot one or more transactions or addresses, you will be able to use the Geo Intelligence module to set
        radii, discover corporate entities and more.
      </Text>
    </FlexLayout>
  );
};

PlottedAddresses.propTypes = {
  data: PT.shape({ addresses: PT.array }),
  meta: PT.shape({
    setAddresses: PT.func,
    renderedObjects: PT.object,
    updateAddressRadius: PT.func,
  }),
  map: PT.object,
};

export default PlottedAddresses;
