import React, { useState } from 'react';
import PT from 'prop-types';
import AsyncCreatable from 'react-select/async-creatable';

import { FlexLayout } from '~/ui';

import components from './components';
import styles from './styles';
import { withFormController } from '~/hocs';

const sizesMap = {
  normal: {
    px: 4,
    py: 3,
  },
  small: {
    px: 3,
    py: 2,
  },
  nopad: {
    px: 0,
  },
};

const widths = {
  f: '100%',
};

const variantStyles = {
  primary: {
    bg: 'background',
  },
  transparent: {
    bg: 'rgba(255, 255, 255, 0.1)',
  },
};

const createOption = (value, label) => ({
  value,
  label,
});

function MultiSelect({
  placeholder = '',
  containerWidth = 'f',
  value = [],
  variant,
  size = 'nopad',
  onChange,
  getItems,
  createItem,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectValues, setSelectValues] = useState(value);

  const promiseOptions = async (inputValue) => {
    setIsLoading(true);
    if (inputValue) {
      return getItems(inputValue).then(({ data }) => {
        let options = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setIsLoading(false);
        return options;
      });
    }
  };

  const handleOnCreate = (inputValue) => {
    setIsLoading(true);
    createItem({ name: inputValue }).then(({ data }) => {
      const { id, name } = data;
      const newValue = [...selectValues, createOption(id, name)];
      setSelectValues(newValue);
      onChange(newValue);
      setIsLoading(false);
    });
  };

  return (
    <FlexLayout
      sx={{ ...sizesMap[size], ...variantStyles[variant], width: widths[containerWidth], borderRadius: 'm' }}
      width="full-div"
      space={3}
      alignItems="center"
    >
      <AsyncCreatable
        components={components}
        placeholder={placeholder}
        styles={styles}
        loading={isLoading}
        isMulti
        isClearable
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onCreateOption={handleOnCreate}
        onChange={(opt, action) => {
          if (action.action === 'clear') {
            setSelectValues([]);
            onChange([]);
          } else {
            if (opt) {
              onChange(opt);
              setSelectValues(opt);
            }
          }
        }}
        value={selectValues}
        noOptionsMessage={() => 'Type to Search...'}
      />
    </FlexLayout>
  );
}

const optionPropType = PT.shape({
  label: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
});

MultiSelect.propTypes = {
  options: PT.arrayOf(optionPropType),
  placeholder: PT.string,
  variant: PT.oneOf(Object.keys(variantStyles)),
  value: PT.array,
  onChange: PT.func,
  containerWidth: PT.oneOf(Object.keys(widths)),
  size: PT.oneOf(Object.keys(sizesMap)),
  getItems: PT.func,
  createItem: PT.func,
};

export default withFormController(MultiSelect);
