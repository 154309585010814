import React, { Fragment, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import config from '~/config';
import PT from 'prop-types';
import { Text, FlexLayout, Input, Icon, Pagination, ListWithLabels, IconWithHover } from '~/ui';
import { debounce } from 'lodash';
import { keyframes } from '@emotion/react';

import { openCorporatesService } from '~/services';
const THROTTLE_TIME = 300;

const Address = ({ borderBottom, address, map, updateAddressRadius }) => {
  const [showData, setShowData] = useState(false);
  const [corporateData, setCorporateData] = useState({
    companies: [],
    page: 1,
    per_page: 30,
    total_count: 0,
    total_pages: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const setRadius = useCallback(
    debounce((newRadius, fixInput, e) => {
      address.plotRadius = newRadius;
      updateAddressRadius(map, address);
      if (fixInput && e) {
        fixInputRadius(newRadius, e);
      }
    }, THROTTLE_TIME),
    [],
  );

  const getData = address.address.geoData.data;

  const cleanupAddressQuery = () => {
    let addressQuery = getData.formatted.toLowerCase();
    const areaNames = Object.freeze({
      COUNTRY: 'country',
      DISTRICT: 'district',
      POSTCODE: 'postcode',
      REGION: 'region',
    });
    //from addressQuery remove all postcode, district, region and country data,
    //eg. "United States", "New York", "Sancta Monica", "90291" for better OpenCorporates results
    getData.components.forEach((c) => {
      Object.values(areaNames).forEach((areaName) => {
        if (c.id.startsWith(areaName)) {
          addressQuery = addressQuery.replaceAll(c.text.toLowerCase(), '');
        }
      });
    });
    //replace all commas with whitespaces, mulitple whitespaces with single, and trim
    addressQuery = addressQuery.replace(/,/g, '').replace(/  +/g, ' ').trim();
    return addressQuery;
  };

  const getOpenCorporatesData = (page) => {
    setIsLoading(true);

    let addressQuery = cleanupAddressQuery();

    openCorporatesService(page, corporateData.per_page, addressQuery)
      .then((response) => {
        setCorporateData(response.data.results);
        setShowData(true);
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  };

  const { lat, lng } = address.address;

  const fixInputRadius = useCallback(
    debounce((newRadius, e) => {
      e.target.value = newRadius;
    }, THROTTLE_TIME),
    [],
  );

  return (
    <FlexLayout flexDirection="column">
      <FlexLayout
        key={address.id}
        bg="grey"
        px={2}
        py={3}
        mt="1px"
        space={6}
        sx={{
          position: 'relative',
          width: '100%',
          borderBottomRightRadius: borderBottom && 'm',
          borderBottomLeftRadius: borderBottom && 'm',
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <FlexLayout flexDirection="column" justifyContent="center" sx={{ width: '240px' }}>
          <Text variant="label-m" color="white">
            {address.name}
          </Text>
        </FlexLayout>
        <FlexLayout flexDirection="row" alignItems="center" space={2}>
          <IconWithHover
            onClick={() => {
              if (!showData && corporateData.total_count === 0) {
                getOpenCorporatesData(1);
              } else if (!showData && corporateData.total_count !== 0) {
                setShowData(true);
              } else {
                setShowData(false);
              }
            }}
            top={6}
            left={-32}
            popupText="Show Corporate Entities"
            icon={!isLoading ? 'briefcase' : 'loading'}
            iconSize="icon-m"
            iconSx={
              isLoading && {
                animationName: keyframes`from { transform: rotate(0deg); } to { transform: rotate(360deg); }`,
                animationDuration: '1s',
                animationFillMode: 'backwards',
                animationIterationCount: 'infinite',
              }
            }
            tooltipWidth={'148px'}
          />
          <IconWithHover
            onClick={() => {
              const newWindow = window.open(`http://maps.google.com/maps?q=&layer=c&cbll=${lat},${lng}`, '_blank');
              if (newWindow) newWindow.opener = null;
            }}
            top={6}
            left={-24}
            popupText="Open Street View"
            icon="streetView"
            iconSize="icon-m"
            tooltipWidth="114px"
          />
          <Input
            containerWidth="radius"
            size="small"
            variant="primary"
            defaultValue={String(address.plotRadius)}
            inputVariant="xSmallFont"
            onChange={(e) => {
              if (e.target.value >= 0 && e.target.value != -0) {
                setRadius(e.target.value);
              } else {
                setRadius(0, true, e);
              }
            }}
          />
        </FlexLayout>
      </FlexLayout>
      {showData && (
        <FlexLayout flexDirection="column" py={3} px={2} sx={{ maxHeight: '340px', overflowY: 'auto' }}>
          <FlexLayout flexDirection="row" justifyContent="space-between" alignItems="center" space={2}>
            <Text variant="label-xs" color="grey-500">
              CORPORATE ENTITIES
            </Text>
            <FlexLayout
              sx={{ cursor: 'pointer' }}
              flexDirection="row"
              alignItems="center"
              onClick={() => setShowData(false)}
            >
              <Icon icon="close" size="icon-s" color="grey-500" />
              <Text variant="label-s" color="grey-500">
                CLOSE
              </Text>
            </FlexLayout>
          </FlexLayout>
          <Text variant="label-s" color="grey-400">
            {feature.place_name}
          </Text>
          {corporateData.companies.length !== 0 ? (
            <FlexLayout flexDirection="column">
              {corporateData.companies.map(
                ({
                  company: {
                    name,
                    company_type,
                    company_number,
                    current_status,
                    incorporation_date,
                    opencorporates_url,
                  },
                }) => {
                  {
                    return (
                      <FlexLayout key={opencorporates_url} flexDirection="column" space={2} mt={4}>
                        {name && (
                          <Text variant="label-m" color="white">
                            {name}
                          </Text>
                        )}
                        <ListWithLabels
                          labelVariant="label-s"
                          itemVariant="label-s"
                          labelColor="grey-400"
                          itemColor="grey-200"
                          listItems={[
                            { label: 'Company Type', item: company_type },
                            { label: 'Company Number', item: company_number },
                            { label: 'Status', item: current_status },
                            { label: 'Incorporation Date', item: incorporation_date },
                          ]}
                        />
                        {opencorporates_url && (
                          <FlexLayout
                            flexDirection="row"
                            alignItems="center"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              const newWindow = window.open(opencorporates_url, '_blank', 'noopener,noreferrer');
                              if (newWindow) newWindow.opener = null;
                            }}
                          >
                            <Text variant="label-s" color="yellow" mr={1}>
                              More Details
                            </Text>
                            <Icon icon="arrowTopRight" size="icon-s" color="yellow" />
                          </FlexLayout>
                        )}
                      </FlexLayout>
                    );
                  }
                },
              )}
              {corporateData.total_pages > 1 && (
                <Pagination
                  onPageChanged={getOpenCorporatesData}
                  pageLimit={corporateData.per_page}
                  startingPage={corporateData.page}
                  totalRecords={corporateData.total_count}
                  textVariant="label-s"
                  height="20px"
                  width="20px"
                  sx={{ mt: 4 }}
                />
              )}
            </FlexLayout>
          ) : (
            <Text variant="label-m" color="red">
              No companies found on this location!
            </Text>
          )}
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

Address.propTypes = {
  address: PT.object,
  renderedObjects: PT.object,
  addresses: PT.array,
  borderBottom: PT.bool,
  map: PT.object,
  updateAddressRadius: PT.func,
  index: PT.number,
};

export default Address;
