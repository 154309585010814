import { theme } from '~/ui';
import sizes from '~/ui/theme/sizes';

const { radii, space, colors, text } = theme;

export default {
  container: (styles, { selectProps: { width } }) => ({
    ...styles,
    width: width === 'full' ? '100%' : 'fit-content',
  }),
  control: (styles) => ({
    ...styles,
    background: 'transparent',
    border: '0 !important',
    height: '40px',
    outline: 'none',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
    },
  }),
  option: (styles) => ({
    ...styles,
    padding: '8px',
    color: 'white',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menu: (styles, { selectProps: { menuWidth = 'select-menu' } }) => ({
    ...styles,
    borderRadius: radii['m'],
    width: sizes[menuWidth],
    right: '0',
    margin: `${space[1]} 0`,
    backgroundColor: '#202225',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '100%',
    padding: 0,
  }),
  option: (_styles, state) => ({
    backgroundColor: state.isFocused ? colors['transparent'] : colors['body'],
    color: colors['white'],
    ...text['label-m'],
  }),
  input: (_styles, state) => ({
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors['white'],
    ...text['label-m'],
    position: 'relative',
    top: '0',
    margin: '0 !important',
    WebkitTransform: 'none',
    MozTransform: 'none',
    MsTransform: 'none',
    Otransform: 'none',
    transform: 'none',
  }),
  valueContainer: (styles, { selectProps: { valuePad } }) => {
    return {
      ...styles,
      padding: valuePad,
    };
  },
};
