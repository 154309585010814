import React from 'react';
import PT from 'prop-types';

import { FlexLayout, Text, Icon } from '~/ui';
import { useHover } from '~/hooks';

const Option = ({ label, icon, onClick }) => {
  const [hovered, hoverHandlers] = useHover();
  const color = hovered ? 'yellow' : 'white';
  return (
    <FlexLayout
      justifyContent="flex-start"
      alignItems="center"
      space={3}
      p={1}
      key={label}
      sx={{ cursor: 'pointer', width: 'full-div' }}
      onClick={onClick}
      {...hoverHandlers}
    >
      {icon ? <Icon icon={icon} color={color} size="icon-m" /> : null}
      <Text color={color} variant="paragraph-s">
        {label}
      </Text>
    </FlexLayout>
  );
};

Option.propTypes = {
  label: PT.string,
  icon: PT.string,
  onClick: PT.func,
};

export default Option;
