export default {
  'page-width': '1318px',
  'half-div': '50%',
  'full-div': '100%',
  'form-wrapper-size': '880px',
  'form-wrapper-height': '670px',
  'form-medium': '440px',
  'input-height': '24px',
  'row-height': '48px',
  'textarea-height': '96px',
  'button-medium': '32px',
  'dropdown-width': '150px',
  'select-menu': '326px',
  'icon-xxs': '12px',
  'icon-xs': '16px',
  'icon-s': '20px',
  'icon-m': '24px',
  'icon-l': '28px',
  'icon-xl': '32px',
  'button-width': '151px',
  'button-height': '43px',
};
