import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '~/hooks';
import { Box, Dropdown } from '~/ui';

const withDropdown = (WrappedComponent) => {
  const withDropdown = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowDropdown(false));

    const onToggle = () => {
      setShowDropdown(!showDropdown);
    };

    return (
      <Box onClick={onToggle} ref={ref}>
        <WrappedComponent clicked={showDropdown} {...props} />
        <Box sx={{ position: 'relative' }}>{showDropdown && <Dropdown {...props} />}</Box>
      </Box>
    );
  };

  return withDropdown;
};

export default withDropdown;
