import PT from 'prop-types';
import React from 'react';
import { Text as ThemeText } from 'theme-ui';

import { theme } from '~/ui';

const Text = React.forwardRef(({ children, color, sx, textAlign, variant, upperCase = false, ...rest }, ref) => {
  return (
    <ThemeText
      color={color}
      ref={ref}
      sx={{
        ...sx,
        textTransform: upperCase ? 'uppercase' : null,
      }}
      variant={variant}
      {...rest}
    >
      {children}
    </ThemeText>
  );
});

Text.propTypes = {
  as: PT.any,
  children: PT.node,
  color: PT.oneOf(Object.keys(theme.colors)),
  sx: PT.object,
  variant: PT.oneOf(Object.keys(theme.text)),
  textAlign: PT.string,
  upperCase: PT.bool,
};

Text.displayName = 'Text';

export default Text;
