import React from 'react';
import PT from 'prop-types';

import { NewCaseForm } from '~/components';

function NewCaseModal({ data }) {
  return <NewCaseForm data={data} />;
}

NewCaseModal.propTypes = {
  data: PT.any,
};

export default NewCaseModal;
