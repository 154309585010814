import React from 'react';

import { riskKeys } from '~/constants';
import { FlexLayout, Text, Icon, Checkbox } from '~/ui';
import PT from 'prop-types';

//for now show only sanctions in the left sidebar
const shownRiskKeys = ['Sanctions'];

const RiskKeys = ({ data: { map, plottedRiskKeys }, meta: { toggleSanctions } }) => {
  const riskKeyClicked = (riskKey) => {
    if (riskKey.name === 'Sanctions') {
      toggleSanctions(map, undefined, true);
    }
  };

  return (
    <FlexLayout bg="grey" sx={{ borderRadius: 'm' }} flexDirection="column">
      <FlexLayout p={2} alignItems="center" justifyContent="space-between" flexGrow="1" sx={{ paddingBottom: 1 }}>
        <Text variant="label-semibold-xs" color="grey-400">
          Risk Key
        </Text>
        <Text variant="label-semibold-xs" color="grey-400" sx={{ cursor: 'pointer' }}>
          Plot
        </Text>
      </FlexLayout>
      <FlexLayout flexDirection="column">
        {riskKeys.map((riskKey) => {
          if (shownRiskKeys.includes(riskKey.name)) {
            return (
              <FlexLayout
                key={riskKey.name}
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{ borderBottom: 'dark', height: '40px' }}
              >
                <FlexLayout alignItems="center" space={4}>
                  <FlexLayout
                    sx={{ bg: riskKey.color, borderRadius: 'm', width: 'icon-m', height: 'icon-m' }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {riskKey.icon ? <Icon icon={riskKey.icon} /> : null}
                  </FlexLayout>
                  <Text variant="label-s">{riskKey.name}</Text>
                </FlexLayout>
                <Checkbox
                  height="icon-m"
                  width="icon-m"
                  isActive={riskKey.name === 'Sanctions' && plottedRiskKeys.sanctions}
                  onChange={() => riskKeyClicked(riskKey)}
                />
              </FlexLayout>
            );
          }
        })}
      </FlexLayout>
    </FlexLayout>
  );
};

RiskKeys.propTypes = {
  data: {
    map: PT.any,
  },
  meta: {
    plottedRiskKeys: PT.object,
    toggleSanctions: PT.func,
  },
};

export default RiskKeys;
