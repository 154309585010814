import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PageLayout } from '~/ui';
import { Header } from '~/components';
import { DashboardContainer } from '~/containers';

function DefaultLayout() {
  return (
    <Switch>
      <PageLayout color="grey" HeaderComponent={Header}>
        <Route exact path="/dashboard" render={(props) => <DashboardContainer {...props} />} />
        <Route exact path="/results" render={(props) => <DashboardContainer {...props} />} />
      </PageLayout>
    </Switch>
  );
}

export default DefaultLayout;
