import PT from 'prop-types';
import React from 'react';

import { theme } from '~/ui';
import { Text } from '~/ui';

const Pill = React.forwardRef(({ value, sx, variant = 'label-s' }, ref) => {
  return (
    <Text variant={variant} py={1} px={2} sx={{ ...sx, borderRadius: 'm', bg: 'polar-grey-blue' }}>
      {value}
    </Text>
  );
});

Pill.propTypes = {
  value: PT.string,
  variant: PT.oneOf(Object.keys(theme.text)),
  sx: PT.any,
};

Pill.displayName = 'Pill';

export default Pill;
