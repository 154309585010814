import React, { createContext, useContext, useState } from 'react';
import PT from 'prop-types';
import { cookie } from '~/utils';

const AuthContext = createContext({});

/**
 * Custom hook to login and logout user for our application.* @param {Node} children children nodes to be rendered
 **/
const UseAuthProvider = (props) => {
  const { children } = props;

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const token = cookie.getItem('token');

  const setLoggedInUser = (user, token) => {
    localStorage.setItem('user', user);
    cookie.setItem('token', token);
    setUser(user);
  };

  const removeLoggedInUser = () => {
    localStorage.removeItem('user');
    cookie.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setLoggedInUser,
        removeLoggedInUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

UseAuthProvider.propTypes = {
  children: PT.node,
};

export default useAuth;

export { UseAuthProvider };
