import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';

import { sessionService } from '~/services';
import { Button, FlexLayout, Box, Text, Input } from '~/ui';

function ResetPasswordForm() {
  const { control, handleSubmit } = useForm({
    mode: 'all',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const history = useHistory();

  function onSubmit(data) {
    const {
      location: { search },
    } = history;
    const token = new URLSearchParams(search).get('token');
    const { password, confirm } = data;

    if (confirm !== password) {
      setErrorMessage('Passwords do not match.');
      setError(true);
    } else {
      if (token) {
        sessionService.reset({ token, password }).then(({ data, error }) => {
          if (error) {
            setErrorMessage(data.message);
            setError(true);
          } else {
            setErrorMessage('');
            setError(false);
            history.push('/');
          }
        });
      } else {
        setErrorMessage('Token is blank.');
        setError(true);
      }
    }
  }

  return (
    <FlexLayout flexDirection="column">
      <Helmet>
        <title>Reset Password | Virturis</title>
      </Helmet>
      <Text variant="paragraph-m" mb={4}>
        RESET PASSWORD{' '}
      </Text>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FlexLayout mb={error ? 3 : 6} space={4} flexDirection="column">
          <Input
            icon={error ? 'lockDanger' : 'lock'}
            color="white"
            type="password"
            name="password"
            control={control}
            placeholder="New Password"
          />
          <Input
            icon={error ? 'lockDanger' : 'lock'}
            color="white"
            type="password"
            name="confirm"
            control={control}
            placeholder="Confirm New Password"
          />
        </FlexLayout>
        {error ? (
          <Text variant="label-s" mb={3} color="grey-400">
            {errorMessage}
          </Text>
        ) : null}
        <FlexLayout width="full-div" alignItems="center">
          <Button
            text="Reset Password"
            variant="primary"
            width="medium"
            type="submit"
            textVariant="label-s"
            icon="arrow"
          />
        </FlexLayout>
      </Box>
    </FlexLayout>
  );
}

export default ResetPasswordForm;
