import PT from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { withFormController } from '~/hocs';

import { FlexLayout } from '~/ui';

import components from './components';
import styles from './styles';

const sizesMap = {
  normal: {
    px: 4,
    py: 3,
  },
  small: {
    px: 3,
    py: 2,
  },
  nopad: {
    px: 0,
  },
};

const widths = {
  full: '100%',
  small: 'fit-content',
};

const variantStyles = {
  primary: {
    bg: 'background',
  },
  transparent: {
    bg: 'rgba(255, 255, 255, 0.1)',
  },
  grey: {
    bg: 'grey',
  },
  nobg: {
    bg: 'transparent',
  },
};

function SingleSelect({
  options = [],
  placeholder = '',
  containerWidth = 'full',
  value,
  width = 'full',
  variant,
  size = 'nopad',
  onChange,
  valuePad,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(value);

  return (
    <FlexLayout
      sx={{ ...sizesMap[size], ...variantStyles[variant], width: widths[containerWidth], borderRadius: 'm' }}
      space={3}
      alignItems="center"
    >
      <Select
        components={components}
        options={options}
        styles={styles}
        placeholder={placeholder}
        onClick={(value) => {
          setOpen(value);
        }}
        value={selectValue}
        onChange={(option) => {
          setOpen(false);
          onChange(option);
          setSelectValue(option);
        }}
        menuIsOpen={open}
        width={width}
        valuePad={valuePad}
        {...props}
      />
    </FlexLayout>
  );
}

const optionPropType = PT.shape({
  label: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
});

SingleSelect.propTypes = {
  options: PT.arrayOf(optionPropType),
  placeholder: PT.string,
  variant: PT.oneOf(Object.keys(variantStyles)),
  value: PT.any,
  width: PT.oneOf(Object.keys(widths)),
  onChange: PT.func,
  containerWidth: PT.oneOf(Object.keys(widths)),
  size: PT.oneOf(Object.keys(sizesMap)),
  color: PT.string,
  valuePad: PT.string,
  onChange: PT.func,
};

export default withFormController(SingleSelect);
