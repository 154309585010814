const alphas = {
  'grey-100': 'rgba(233, 236, 239, 1)',
  'grey-200': 'rgba(222, 226, 230, 1)',
  'grey-300': 'rgba(206, 212, 218, 1)',
  'grey-400': 'rgba(173, 181, 189, 1)',
  'grey-500': 'rgba(108, 117, 125, 1)',
};

const palette = {
  'adverse-media': '#4ECDC4',
  blackest: '#19212F',
  body: '#202225',
  'text-body': '#303030',
  grey: '#2B3038',
  'grey-white': '#E9ECEF',
  'company-registry-data': '#EC7634',
  dark: '#1F1F1F',
  danger: '#DC3545',
  'dark-blue': '#122623',
  'forest-blue': '#29353D',
  'money-business': '#CBC0AD',
  offshore: '#4175F6',
  PEP: '#863AD1',
  'polar-blue': '#556577',
  'polar-grey-blue': '#33404E',
  sanctions: '#AF1524',
  yellow: '#FFD600',
  white: '#F8F9FA',
  transparent: 'rgba(255, 255, 255, 0.1)',
  warning: '#F29E18',
  safe: '#31C797',
  danger: '#DC3545',
  transparent: 'transparent',
};

export default {
  alphas,
  palette,
};
