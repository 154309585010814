import React, { Fragment, useState } from 'react';
import PT from 'prop-types';
import { FlexLayout, Icon, Text, Input, Box, theme } from '~/ui';
import { getAddress } from '~/utils';
import colors from '~/ui/theme/colors';

const PlacesAutocomplete = ({
  control,
  placeholder = '',
  setInputValues,
  size,
  variant,
  setValue,
  value,
  color = 'white',
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const handleInput = (e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
    if (value) {
      getAddress(value).then((suggestions) => {
        setSuggestions(suggestions);
      });
    }
  };

  const handleSelect = ({ place_name }) => {
    setInputValues('query', place_name);

    setSuggestions([]);
    setValue(place_name);
  };

  const renderSuggestions = () =>
    suggestions.map((suggestion) => {
      const { name, place_name } = suggestion;

      return (
        <FlexLayout
          key={name}
          sx={{ width: 'full-div', cursor: 'pointer' }}
          onClick={() => handleSelect(suggestion)}
          alignItems="center"
          space={2}
        >
          <Icon icon="geo" /> <Text variant="label-m">{place_name}</Text>
        </FlexLayout>
      );
    });

  return (
    <Box as="div" sx={{ position: 'relative' }}>
      <Input
        value={value}
        variant={variant}
        size="normal"
        placeholderColor={color}
        placeholder={placeholder}
        type="text"
        onChange={handleInput}
      />
      <Input control={control} name="query" type="text" displayNone={true} />

      {suggestions.length > 0 ? (
        <FlexLayout
          alignItems={'center'}
          space={4}
          p={6}
          sx={{ bg: 'body', borderRadius: 'm', position: 'absolute', border: 'border-input', width: 'full-div' }}
          flexDirection="column"
        >
          {renderSuggestions()}
        </FlexLayout>
      ) : null}
    </Box>
  );
};

PlacesAutocomplete.propTypes = {
  control: PT.object,
  name: PT.string,
  color: PT.oneOf(Object.keys(theme.colors)),
  rules: PT.object,
  placeholder: PT.string,
  setInputValues: PT.func,
  size: PT.string,
  variant: PT.string,
  setValue: PT.func,
  value: PT.string,
};

export default PlacesAutocomplete;
