import React from 'react';
import axios from 'axios';
import config from '~/config';

export const getAddress = (query, relevance = 0.8, limit = 5, types = 'address') => {
  return axios
    .get(`${config.geocoding_endpoint}${query}.json?limit=${limit}&access_token=${config.mapbox_token}`)
    .then(({ data: { features } }) => {
      return features.map((feature) => {
        if (feature.place_name) {
          const { place_name } = feature;
          return { place_name };
        }
      });
    });
};
