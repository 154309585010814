import React from 'react';
import PT from 'prop-types';
import { Controller } from 'react-hook-form';

/**
 * High order component for wrapping inputs and other form elements with react-hook-form Controller.* @param {Node} WrappedComponent component that is being wrapped
 **/
const withFormController = (WrappedComponent) => {
  /**
   * Helper function when wrapping component.* @param {object} props object containing react-hook-form props like control, rules and name. Also default
   * props that are just being propagated to @param {Node} WrappedComponent
   **/

  const WithForm = React.forwardRef((props, ref) => {
    return props.control ? (
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        defaultValue={props.defaultValue}
        render={({ field: { onChange, value } }) => (
          <WrappedComponent value={value || ''} ref={ref} onChange={onChange} {...props} />
        )}
      />
    ) : (
      <WrappedComponent ref={ref} {...props} />
    );
  });

  WithForm.propTypes = {
    control: PT.object,
    name: PT.string,
    rules: PT.object,
    defaultValue: PT.any,
  };

  WithForm.displayName = 'WithForm';

  return WithForm;
};

withFormController.propTypes = {
  WrappedComponent: PT.node,
};

export default withFormController;
