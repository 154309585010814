import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'theme-ui';

import '~/styles/index.css';
import '~/ui/assets/fonts/din/index.css';

import { App } from '~/components';
import { theme } from '~/ui';
import { UseAuthProvider } from '~/hooks/useAuth';
import { UseModalProvider } from '~/hooks/useModal';
import { UseCaseHeaderProvider } from './hooks/useCaseHeader';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <UseModalProvider>
      <UseAuthProvider>
        <UseCaseHeaderProvider>
          <App />
        </UseCaseHeaderProvider>
      </UseAuthProvider>
    </UseModalProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
