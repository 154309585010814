import PT from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';

import { Box } from '~/ui';
import * as icons from '~/ui/assets/icons';
import theme from '~/ui/theme';

const { colors } = theme;

const sizesMap = {
  xs: '12px',
  s: '16px',
  m: '20px',
  mm: '24px',
  l: '32px',
  xl: '42px',
  xxl: '240px',
};

const Icon = React.forwardRef(({ color = 'white', icon, size = 'm', sx, onClick, ...rest }, ref) => {
  const iconSrc = icons[icon];
  if (!iconSrc) {
    return null;
  }

  return (
    <Box
      ref={ref}
      size={size}
      sx={{
        height: sizesMap[size],
        width: sizesMap[size],
        ...sx,
        alignItems: 'center',
        color,
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: 'center',
      }}
      onClick={onClick}
      {...rest}
    >
      <InlineSvg fill={colors[color]} color={colors[color]} src={iconSrc} />
    </Box>
  );
});

export const iconKeyPropTypes = PT.oneOf(Object.keys(icons));

Icon.propTypes = {
  color: PT.oneOf(Object.keys(colors)),
  icon: iconKeyPropTypes.isRequired,
  size: PT.oneOf(Object.keys(sizesMap)),
  sx: PT.object,
  onClick: PT.func,
};

Icon.displayName = 'Icon';

export default Icon;
