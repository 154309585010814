import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

import { sessionService } from '~/services';
import { Box, Button, FlexLayout, Input, Text } from '~/ui';

function ForgotPasswordForm() {
  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
  });
  const [email, setEmail] = useState('');
  const [submited, setSubmitted] = useState(false);

  const history = useHistory();

  function onSubmit(body) {
    sessionService.forgot(body).then(() => {
      setSubmitted(true);
      setEmail(body.email);
    });
  }

  function renderResetPasswordForm() {
    return (
      <FlexLayout flexDirection="column">
        <Helmet>
          <title>Forgot Password | Virturis</title>
        </Helmet>
        <Text variant="paragraph-m" mb={2}>
          FORGOT YOUR PASSWORD?
        </Text>
        <Text variant="label-s" mb={4} color="grey-400">
          Type in the email address you used at signup to receive a recovery link.
        </Text>
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <FlexLayout mb={6} space={4} flexDirection="column">
            <Input
              control={control}
              color="white"
              icon="envelope"
              type="text"
              name="email"
              placeholder="Email address"
            />
          </FlexLayout>
          <FlexLayout space={4}>
            <Button
              text="Recover Password"
              variant="primary"
              type="submit"
              width="medium"
              textVariant="label-s"
              icon="arrowRight"
            />
            <Button
              text="Cancel"
              textVariant="label-s"
              textColor="white"
              width="medium"
              variant="secondary"
              onClick={() => history.push('/')}
            />
          </FlexLayout>
        </Box>
      </FlexLayout>
    );
  }

  function renderSentContent() {
    return (
      <FlexLayout flexDirection="column">
        <Text variant="paragraph-m" mb={8}>
          PASSWORD RECOVERY SENT
        </Text>
        <Text variant="label-s" mb={3} color="grey-400">
          An email has been sent. Click the link in the email to recover your password and login to GeoRisk.
        </Text>
        <FlexLayout space={4}>
          <Button
            text="Back"
            variant="primary"
            width="medium"
            textVariant="label-s"
            icon="arrowLeft"
            left={true}
            onClick={() => history.push('/')}
          />
          <Button
            text="Send another email"
            textVariant="label-s"
            textColor="white"
            width="medium"
            variant="secondary"
            onClick={() => {
              onSubmit({ email: email });
            }}
          />
        </FlexLayout>
      </FlexLayout>
    );
  }

  return submited ? renderSentContent() : renderResetPasswordForm();
}

export default ForgotPasswordForm;
