import React, { useEffect } from 'react';
import PT from 'prop-types';
import { useForm } from 'react-hook-form';

import { Box, Button, FlexLayout, Text, TextArea, Input, Select, MultiSelect } from '~/ui';
import { useModal } from '~/hooks';
import { statuses } from '~/constants';
import { casesService, labelsService } from '~/services';

const NewCaseForm = ({ data }) => {
  const { name, labels, description, status, id } = data;
  const { control, formState, handleSubmit, setValue } = useForm();
  const { hideModal } = useModal();
  const updating = Object.keys(data).length !== 0;

  useEffect(() => {
    if (data !== {}) {
      setValue('name', name);
      setValue('description', description);
    }
  }, []);

  const caseData = (data) => {
    const { labels, description, name, status } = data;
    return {
      labelIds: labels.map((label) => label.value),
      description,
      status: status.enum,
      name,
    };
  };

  const onSubmit = (body) => {
    if (updating) {
      let object = caseData(body);
      delete object.summary;
      casesService.updateCase(id, object).then(({ data }) => {
        hideModal(data);
      });
    } else {
      casesService.createNewCase(caseData(body)).then(({ data }) => {
        hideModal(data);
      });
    }
  };

  const CustomError = ({ name, errors }) =>
    errors && errors[name] ? (
      <Text variant="label-s" color="warning" mt={2}>
        {errors[name].message}
      </Text>
    ) : null;

  CustomError.propTypes = {
    name: PT.string,
    errors: PT.any,
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <FlexLayout mb={6} space={2} flexDirection="column">
        <Text variant="label-semibold-m">CASE NAME</Text>
        <Input
          control={control}
          type="text"
          name="name"
          color="white"
          size="normal"
          variant="transparent"
          inputVariant="mediumFont"
          placeholder=""
          rules={{
            required: 'Case name is required.',
          }}
        />
        <CustomError errors={formState.errors} name="name" />
      </FlexLayout>
      <FlexLayout mb={6} space={2} flexDirection="column">
        <Text variant="label-semibold-m">LABELS</Text>
        <MultiSelect
          control={control}
          defaultValue={labels ? labels : []}
          type="text"
          color="white"
          size="nopad"
          name="labels"
          variant="transparent"
          inputVariant="mediumFont"
          placeholder=""
          getItems={labelsService.getLabels}
          createItem={labelsService.createLabel}
        />
      </FlexLayout>
      <FlexLayout mb={6} space={2} flexDirection="column">
        <Text variant="label-semibold-m">DESCRIPTION</Text>
        <TextArea
          control={control}
          name="description"
          color="white"
          size="normal"
          variant="transparent"
          placeholder=""
          rules={{
            required: 'Case description is required.',
          }}
        />
        <CustomError errors={formState.errors} name="description" />
      </FlexLayout>
      <FlexLayout mb={6} space={2} flexDirection="column">
        <Text variant="label-semibold-m">STATUS</Text>
        <Select
          control={control}
          defaultValue={status ? status : statuses[0]}
          name="status"
          color="white"
          size="nopad"
          valuePad="0 16px"
          variant="transparent"
          menuWidth="full-div"
          options={statuses}
        />
      </FlexLayout>
      <FlexLayout width="full-div">
        <Button
          text={updating ? 'Update Case' : 'New Case'}
          type="submit"
          textVariant="heading-semibold-s"
          width="fullWidth"
          textColor="body"
          variant="third"
          size="normal"
        />
      </FlexLayout>
    </Box>
  );
};

NewCaseForm.propTypes = {
  data: PT.shape({
    name: PT.string,
    labels: PT.arrayOf(PT.any),
    description: PT.string,
    status: PT.any,
    id: PT.string,
  }),
};

export default NewCaseForm;
