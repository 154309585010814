import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useForm } from 'react-hook-form';

import { Box, Button, FlexLayout, Text, Input, PlacesAutocomplete } from '~/ui';
import { useModal } from '~/hooks';
import { addressesService } from '~/services';

const NewAddressForm = (props) => {
  const { data } = props;
  const { control, handleSubmit, setValue } = useForm();
  const [value, setAutocompleteValue] = useState('');
  const updating = data.id ? true : false;
  const { hideModal } = useModal();

  useEffect(() => {
    if (updating) {
      const { addressName, name } = data;
      setValue('addressName', addressName);
      setValue('query', name);
      setAutocompleteValue(name);
    }
  }, []);

  const onSubmit = (body) => {
    if (updating) {
      addressesService.updateAddress(data.id, body).then(({ data, error }) => {
        if (!error) {
          hideModal(data);
        }
      });
    } else {
      addressesService.createAddress({ ...body, caseId: data.caseId }).then(({ data, error }) => {
        if (!error) {
          hideModal(data);
        }
      });
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <FlexLayout mb={6} space={2} flexDirection="column">
        <Text variant="label-semibold-m">ADDRESS</Text>
        <PlacesAutocomplete
          control={control}
          value={value}
          setValue={setAutocompleteValue}
          setInputValues={setValue}
          color="grey-500"
          variant="transparent"
          placeholder="Type in the address..."
        />
      </FlexLayout>
      <FlexLayout mb={6} space={2} flexDirection="column">
        <Text variant="label-semibold-m">NAME</Text>
        <Input
          control={control}
          type="text"
          name="addressName"
          placeholderColor="grey-500"
          size="normal"
          placeholder="The name for this address..."
          variant="transparent"
          inputVariant="mediumFont"
          rules={{
            required: 'Address name is required.',
          }}
        />
      </FlexLayout>
      <FlexLayout width="full-div">
        <Button
          text={updating ? 'Update Address' : 'Add Address'}
          type="submit"
          textVariant="heading-semibold-s"
          width="fullWidth"
          textColor="body"
          variant="third"
          size="normal"
        />
      </FlexLayout>
    </Box>
  );
};

NewAddressForm.propTypes = {
  data: PT.any,
};

export default NewAddressForm;
