import { VirturisApi } from '~/apis';

function createLabel(body) {
  return VirturisApi.post('label/create', body);
}

function getLabels(term) {
  return VirturisApi.get(`label?query=${term}`);
}

const labelsService = {
  createLabel,
  getLabels,
};

export default labelsService;
