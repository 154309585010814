import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';
import colors from '~/ui/theme/colors';
import typography from '~/ui/theme/typography';
import sizes from '~/ui/theme/sizes';
import borders from '~/ui/theme/borders';

function Checkbox({
  isActive = false,
  label,
  children,
  textVariant = 'label-m',
  textColor = 'white',
  height = 's',
  width = 's',
  onChange,
  border,
}) {
  const icon = isActive ? 'checkmark' : null;

  return (
    <FlexLayout alignItems="center" space={4} sx={{ cursor: 'pointer' }} onClick={() => onChange(label)}>
      <FlexLayout
        alignItems="center"
        justifyContent="center"
        flexShrink="0"
        sx={{
          backgroundColor: 'body',
          border,
          borderRadius: 's',
          height: height,
          width: width,
        }}
      >
        {icon && <Icon color="white" icon={icon} size="xs" />}
      </FlexLayout>
      <FlexLayout alignItems="center" space={2} sx={{ width: '100%' }}>
        {children}
        {label && (
          <Text color={textColor} variant={textVariant}>
            {label}
          </Text>
        )}
      </FlexLayout>
    </FlexLayout>
  );
}

Checkbox.propTypes = {
  isActive: PT.bool,
  label: PT.string,
  onChange: PT.func,
  children: PT.node,
  border: PT.oneOf(Object.keys(borders)),
  textVariant: PT.oneOf(Object.keys(typography.variants)),
  textColor: PT.oneOf(Object.keys(colors.palette)),
  height: PT.oneOf(Object.keys(sizes)),
  width: PT.oneOf(Object.keys(sizes)),
};

export default Checkbox;
